import React from 'react';

const DocumasterFont = ({symbol, label, style}) => {
  const s = style ? style : ''
  return (
    <i title={label} className={`documaster ${symbol} ${s}`}/>
  );
}

export default DocumasterFont;
