import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {
    DataAPI,
    MutationAPI,
    PrinterWebInfo,
    useData,
    useMutator
} from "../../services/API";
import {useHistory, useParams} from "react-router-dom";
import {RouteComponentProps} from "react-router-dom";
import {ModalUrl, useHandleClose} from "../../commons/ModalHelpers";
import PrintersTab from "../PrintersTab";
import Notifications from "../Notifications";

const DeletePrinter = (props: RouteComponentProps) => {

    const history = useHistory();
    const { id } = useParams();

    const { isLoading, error, data } = useData(id, 'printer', DataAPI.getPrinterDetails);

    const handleClose = useHandleClose(PrintersTab.url, true);
    const deletePrinter = useMutator(MutationAPI.deletePrinter, { afterSuccess: handleClose });

    const handleDelete = () => deletePrinter.mutate(id);

    if (isLoading) return null;
    if (error)  {
        Notifications.pushNew(error.message, 'danger');
        return null;
    }

    const printer: PrinterWebInfo = data;

    return (
        <Modal size="sm" show={true} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{printer.serialNumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Czy napewno chcecsz usunąć drukarkę?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>Usuń</Button>
                <Button variant="primary" onClick={()=>history.goBack()}>Anuluj</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeletePrinter;

DeletePrinter.url = `/printers${ModalUrl}delete/:id`;
DeletePrinter.buildUrl = printer => DeletePrinter.url.replace(':id', printer)