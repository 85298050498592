import React from 'react';

export  type FontProps = { icon: string, label: ?string };

const Font = (props: FontProps) => {
    return <i title={props.label} className={`fas ${props.icon} ${props.className != null ? props.className : ''}`}></i>
}

export const DocumasterFont = (props: FontProps) => {
    return <i title={props.label} className={`di ${props.icon}`}></i>
}

export const SpinnerIcon = () => <Font icon="fa-spinner fa-spin" />
export const Back = () => <Font icon="fa-arrow-left" />

export const PageLeft = () => <Font icon="fa-chevron-left" />
export const PageRight = () => <Font icon="fa-chevron-right" />

export const Undo = () => <Font icon="fa-undo" />
export const Search = () => <Font icon="fa-search" />
export const ZoomIn = (props) => <Font icon="fa-search-plus" {...props}/>
export const ZoomOut = () => <Font icon="fa-search-minus" />
export const Danger = () => <Font icon="fa-exclamation-triangle" className="danger-icon" />
export const Info = () => <Font icon="fa-info-circle" className="info-icon"/>

export const Folder = () => <Font icon="fa-folder" />
export const CaretRight = () => <Font icon="fa-caret-right" />
export const CaretDown = () => <Font icon="fa-caret-down" />
export const Trash = (props) => <Font icon="fa-trash" {...props}/>

export const Exchange = (props) => <Font icon="fa-exchange-alt" {...props}/>
export const Share = (props) => <Font icon="fa-share-square" {...props}/>
export const Video = (props) =>  <Font icon="fa-video" {...props}/>
export const WindowClose = (props) =>  <Font icon="fa-window-close" {...props}/>
export const CircleCheck = (props) =>  <Font icon="fa-check-circle" {...props}/>
export const CircleCross = (props) =>  <Font icon="fa-times-circle" {...props}/>

export const User = (props) =>  <Font icon="fa-user" {...props}/>
export const Users = (props) =>  <Font icon="fa-users" {...props}/>
export const Backspace = (props) =>  <Font icon="fa-backspace" {...props}/>
export const PlusSquare = () => <Font icon="fa-plus-square"/>
export const Download = () => <Font icon="fa-download"/>
export const PDF = () => <Font icon="fa-file-pdf"/>

export const VideoType = () => <Font icon="fa-file-video"/>
export const AudioType = () => <Font icon="fa-file-audio"/>
export const DocumentType = () => <Font icon="fa-file-alt"/>
export const ImageType = () => <Font icon="fa-file-image"/>

export const Archive = () => <Font icon="fa-archive"/>
export const LevelUp = () => <Font icon="fa-level-up-alt" />
export const Copy = () => <Font icon="fa-copy" />

export const SortUp = () => <Font icon="fa-sort-up" />
export const SortDown = () => <Font icon="fa-sort-down" />

export const Bell = () => <Font icon="fa-bell" />
export const Ellipsis = () => <Font icon="fa-ellipsis-v" />

export const Contrast = () => <Font icon="fa-adjust" />
export const LargeFont = () => <Font icon="fa-font" />