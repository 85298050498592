import React, {useContext, useEffect} from 'react';
import { withRouter, useParams } from 'react-router-dom';
import {Formik} from "formik";
import {Button, Form} from "react-bootstrap";
import {ButtonLink, FormButtonGroup, FormikControl, FormRowGroup, Loading} from "./Components";
import TonersInfo from "./TonersInfo";
import type {PrinterEditable, PrinterWebInfo} from "../services/API";
import {RouteComponentProps} from "react-router-dom";
import API, {DataAPI, DbHandler, MutationAPI, useData, useMutator} from "../services/API";
import {SessionContext} from "./SessionContext";
import PrinterNotifications from "./PrinterNotifications";
import PrinterHistory from "./PrinterHistory";
import {Validation} from "../commons/Utils";
import {Helmet} from "react-helmet";
import DeletePrinter from "./modals/DeletePrinter";
import {useQueryClient, UseMutationResult} from "react-query";

const PrintersDetails = (props: RouteComponentProps) => {

  const ctx = useContext(SessionContext);

  const { id } = useParams();
  const { isLoading, error, data, isFetching, key, invalidate } = useData(id, 'printer', DataAPI.getPrinterDetails); //, { refetchInterval: 1000 }

  const updatePrinter: UseMutationResult<PrinterWebInfo> = useMutator(MutationAPI.updatePrinter, {
    customMsg: 'Zmiany zostały zapisane.', afterSuccess: invalidate
  });

  //jeśli zrobimy alias przy destrukturyzacji nie będzie można ustawić typu
  let printer: PrinterWebInfo = data;

  const queryClient = useQueryClient();

  useEffect(()=>{
    if (printer) {
      const handler: DbHandler = API.clientApi.registerHandler('printer', (change, id)=> {
        if (id === printer.id) queryClient.invalidateQueries(key).then(()=>{});
      });
      return ()=>{
        handler.unregister();
      }
    }
  }, [printer]);

  const submit = (pwi: PrinterWebInfo, {setSubmitting}) => {
    setSubmitting(true);

    let value: PrinterEditable = {};
    value.id = pwi.id;
    value.nis = pwi.nis;
    value.clientId = pwi.clientId;
    if (!pwi.lwi || !pwi.lwi.id) value.lwi = null;
    else value.lwi = pwi.lwi;
    value.description = pwi.description;
    value.additionalData = pwi.additionalData;


    updatePrinter.mutate(value, { onSettled: ()=>setSubmitting(false)})
  }

  const validate = (values) => {
    let err = {};
    Validation.add(err, 'nis', Validation.length(values.nis, 64));
    Validation.add(err, 'clientId', Validation.length(values.nis, 64));
    Validation.add(err, 'description', Validation.length(values.description, 254));
    return err;
  }

  const getLocations = (filter: string) => {
    return API.getLocations(filter).then(res=>res.data);
  }

  if (isLoading) return <Loading />;
  if (error) return <p>{error.message}</p>;

  const initial = printer;
  if(initial.alerts) initial.alertsFormatted = printer.alerts.join(', ');

  return <>
      <h2>Szczegóły {isFetching ? <span>- aktualizowanie...</span> : null}</h2>
      <Helmet>
        <title>{printer.name}</title>
      </Helmet>
      <Formik initialValues={initial} onSubmit={submit} validate={validate} enableReinitialize={true}>
        {({handleSubmit, values, isSubmitting, handleReset, isValid}) => (
            <Form noValidate onSubmit={handleSubmit} className="form form-change-password">
              <FormRowGroup controlId="nameId" label="Nazwa">
                <FormikControl type="display" name="name"/>
              </FormRowGroup>
              <FormRowGroup controlId="lastContacted" label="Ostatnia aktualizacja">
                <FormikControl type="display" name="lastContacted"/>
              </FormRowGroup>
              <FormRowGroup controlId="nisId" label="NIS">
                <FormikControl type="text" name="nis"/>
              </FormRowGroup>
              <FormRowGroup controlId="clientId" label="Id klienta">
                <FormikControl type="text" name="clientId"/>
              </FormRowGroup>
              {/*konwertujemy to pole ręcznie*/}
              <FormRowGroup controlId="locationId" label="Lokacja">
                <FormikControl id="printerLocationId" type="oracle" onSearch={getLocations} name="lwi"/>
              </FormRowGroup>

              <FormRowGroup controlId="descId" label="Opis">
                <FormikControl type="textarea" name="description"/>
              </FormRowGroup>
              <FormRowGroup label="Dodatkowe pola" controlId="additionalId">
                <FormikControl type="map" name="additionalData" />
              </FormRowGroup>
              <FormRowGroup controlId="ipId" label="Numer IP">
                <FormikControl type="display" name="ip"/>
              </FormRowGroup>

              <FormRowGroup controlId="macID" label="MAC">
                <FormikControl type="display" name="macNumber"/>
              </FormRowGroup>
              <FormRowGroup controlId="snId" label="Numer seryjny">
                <FormikControl type="display" name="serialNumber"/>
              </FormRowGroup>
              <FormRowGroup controlId="totalCntId" label="Licznik główny">
                <FormikControl type="display" name="totalCounter"/>
              </FormRowGroup>

              {printer.monoCounter > 0 ?
                  <FormRowGroup controlId="monoCntId" label="Licznik mono">
                    <FormikControl type="display" name="monoCounter"/>
                  </FormRowGroup> : null}
              {printer.colorCounter > 0 ?
                  <FormRowGroup controlId="colorCntId" label="Licznik kolor">
                    <FormikControl type="display" name="colorCounter"/>
                  </FormRowGroup> : null}

              {printer.scanBWCounter > 0 ?
                  <FormRowGroup controlId="scanMonoCntId" label="Skany mono">
                    <FormikControl type="display" name="scanBWCounter"/>
                  </FormRowGroup> : null}
              {printer.scanColorCounter > 0 ?
                  <FormRowGroup controlId="scanColorCntId" label="Skany kolor">
                    <FormikControl type="display" name="scanColorCounter"/>
                  </FormRowGroup> : null}

              {printer.tonerBlack > 0 ?
                  <FormRowGroup controlId="colorCntId" label="Poziom tonerów">
                    <TonersInfo printer={printer}/>
                  </FormRowGroup> : null}
              {printer.alerts ?
                  <FormRowGroup controlId="alertsID" label="Alerty">
                    <FormikControl type="display" name="alertsFormatted"/>
                  </FormRowGroup> : null}
              <FormButtonGroup>
                <Button variant="primary" disabled={isSubmitting || !isValid} type="submit">Zapisz</Button>
                <Button variant="primary" onClick={handleReset}>Anuluj</Button>
                {ctx.user.admin ?
                    <ButtonLink variant="danger" to={DeletePrinter.buildUrl(printer.id)}>Usuń</ButtonLink>
                    : null
                }
              </FormButtonGroup>
            </Form>
        )}
      </Formik>

      <PrinterNotifications printerId={printer.id} />
      <PrinterHistory printer={printer.id} />
    </>;
}

PrintersDetails.url = '/printers/:id';

export default withRouter(PrintersDetails);
