import Cookies from 'universal-cookie';
import {format} from 'date-fns';

export const Validation = {

    add: (validationObj: {}, fieldName: string, msg: ?string): void => {
        if (msg) {
            let current = validationObj[fieldName];
            if (current) current += msg;
            else validationObj[fieldName] = msg;
        }
    },
    regexp: (value: string, regexp: RegExp, msg: string) => regexp.test(value) ? null : msg,
    length: (value: string, length: number, msg: string)=>{
        return !value || value.length >= length ? null : msg;
    },
    present: (value: string, msg = 'Wartość nie może byc pusta'): string => (value && value.length > 0) ? null : msg,
    equal: (value1: any, value2: any, msg: string): string => value1 === value2 ? null : msg
}

export const keyPressNavigate = (event: KeyboardEvent, conditions: (KeyboardEvent, any)=>boolean, data: any, func: (any)=>void) => {
    console.log('keypress: ', event.key)
    if (typeof conditions === 'function' && !conditions(event, data)) return;
    if (event.key === 'Enter' || event.key === ' ') func(data);
}


export const BoolValue = Object.freeze({
    label: '',
    items: [
        { label: 'Tak', value: 'true' },
        { label: 'Nie', value: 'false' }
    ]
});

export const SESSION_COOKIE = 'MSSessionId-internal';
export const cookies = new Cookies();


export const formatDate = (date: number, dateFormat: string = 'dd.MM.yyyy HH:mm'): string => {
    if (!date) return "-";
    return format(new Date(date), dateFormat);
}

export const VisibilityHelper = (condition: Condition, display: Display = 'table-cell'): string => {
    switch (condition) {
        case 'xs-up': return 'd-none d-sm-' + display;
        case 'sm-up': return 'd-none d-md-' + display;
        case 'md-up': return 'd-none d-lg-' + display;
        default:
        case 'lg-up': return 'd-none d-xl-' + display;
    }
}

export const isUndef = (value: any) => typeof value === 'undefined';
export const isFun = (value: any) => typeof value === 'function';

/**
 * nie jestem pewien czy to sie nadaje do uzycia w react (useref + usecallback)
 * TODO sprawdzic poza react query ktore spamuje czesto zapytaniami
 * @param func
 * @param delay
 * @returns {(function(): void)|*}
 */
export const debounce = (func, delay) => {
    let inDebounce;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
    }
}