import React, { useMemo} from "react";
import {OrdinalColumn} from "./ReactTable";

import {DataAPI} from "../services/API";
import PrinterStates from "./PrinterStates";
import PrinterDetails from "./PrinterDetails";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";
import {VisibilityHelper} from "../commons/Utils";
import TonersInfo from "./TonersInfo";
import ReactQueryTable from "./ReactQueryTable";

const formatCnt = (o: ?number) => !o ? '[brak]' : o;

const PrintersTab = (props: RouteComponentProps) => {

  const columns = useMemo(()=>[
    OrdinalColumn,
    { Header: 'Nazwa drukarki', accessor: 'name' },
    { Header: 'NIS', accessor: 'nis', className: VisibilityHelper('xs-up') },
    { Header: 'Lokacja', accessor: 'location', className: VisibilityHelper('md-up'),
      filterColumn: (data)=>data.substring(data.lastIndexOf('/') + 1, data.length)
    },
    { Header: 'Numer seryjny', accessor: 'serialNumber', className: VisibilityHelper('md-up') },
    { Header: 'IP', accessor: 'ip', disableSortBy: true, className: VisibilityHelper('md-up')},
    { Header: 'Liczniki', accessor: 'mainCounter',
      Cell: ({row}) => {
        const o = row.original;
        const additional = o.monoCounter ? ' ( M:' + o.monoCounter + '/K:' + formatCnt(o.colorCounter) + ')': '';
        const scan = o.scanBWCounter || o.scanColorCounter ? ' | S - M: ' + formatCnt(o.scanBWCounter) + '/K: ' + formatCnt(o.scanColorCounter) : '';
        return <span>G: {o.totalCounter}{additional}{scan}</span>;
      }, disableSortBy: true
    },
    { Header: 'Poziom tonerów', accessor: 'tonerState',
      Cell: ({row}) =><TonersInfo printer={row.original}/>,
      disableSortBy: true
    },
    { Header: 'Stan', accessor: 'stateSeverity',
      Cell: ({row}) =><PrinterStates short={false} maxStates={1} status={row.original.errorStates} />,
      disableSortBy: true
    },
    {
      Header: 'Ostatnia aktualizacja', accessor: 'lastUpdate', className: VisibilityHelper('md-up'),
      Cell: ({row}) => {
        const o = row.original;
        return o.lastContacted ? new Date(o.lastContacted).toLocaleString() : 'brak';
      }
    }
  ], []);

  const history = useHistory();

  const rowSelect = (printer) => {
    history.push(PrinterDetails.url.replace(':id', printer.id));
  }

  return <>
    <Helmet>
      <title>Drukarki</title>
    </Helmet>
    <ReactQueryTable type="printer" columns={columns} onClick={rowSelect} queryFunction={DataAPI.getPrinters} />
  </>
}

PrintersTab.url = '/printers';

export default PrintersTab;