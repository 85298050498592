import React from 'react';
import API from '../services/API';
import Cookies from 'universal-cookie';
import {emitter} from '../services/API';
import type { WebCredentials} from "../services/API";
import {RouteComponentProps, withRouter} from "react-router-dom";
import type {SessionContextType} from "./SessionContext";
import {SessionContext} from "./SessionContext";
import {Loading} from "./Components";
import Login from "./Login";
import Notifications from "./Notifications";


const COOKIE_NAME = 'globalservice2';
const cookies = new Cookies();

class _SessionContextProvider extends React.Component<RouteComponentProps, SessionContextType> {
  constructor(props, context) {
    super(props, context);

    this.onAuth = (userInfo, rememberMe, fromSid) => {
      console.log('auth: ', userInfo);
      fromSid = fromSid || false;

      if (!fromSid) {
        let opts;
        if (rememberMe) {
          const nextYear = new Date();
          nextYear.setFullYear(nextYear.getFullYear() + 1);
          opts = { path: '/',
            expires: nextYear,
            maxAge: 14 * 24 * 3600000
           }
        } else {
          opts = { path: '/' };
        }
        cookies.set(COOKIE_NAME, userInfo.sessionId, opts);
      }

      this.setState({
        user: userInfo,
        needsConsents: userInfo.needsConsents,
        logged: userInfo.needsConsents ? false : true
      });
    }

    this.acceptRules = (callback) => {
      API.acceptRules().then(()=>{
        this.setState({ logged: true, needsConsents: false }, () => {
          callback();
        });
      }).catch((e)=>{
        console.log(e);
      });
    }

    /**
     * metoda wywolywana jawnie z panelu logowania
     */
    this.login = (credentials: WebCredentials, callback = ()=>{}) => {
      API.authenticate(credentials).then((userInfo) => {
        if (userInfo != null) {
          this.onAuth(userInfo, credentials.rememberMe);
        }
        callback(userInfo);
      }, (reason)=>{
        Notifications.pushNew(reason, 'danger');
        callback(null);
      });
    }

    this.logout = (cb) => {
       API.signout().then(()=>{
        cookies.set(COOKIE_NAME, null);
        this.setState({
          user: {},
          logged: false,
          needsConsents: false
        }, ()=> {
            this.props.history.push(Login.url);
        });
      });
    }

    /**
     * metoda wywolywana przy starcie
     */
    this.checkCookie = () => {
      let sid = cookies.get(COOKIE_NAME);
      if (sid) {
        console.log('session id found, trying to authenticate');
        API.authenticate({sessionId: sid}).then(userInfo => {
          console.log('got response: ', userInfo);
          if (userInfo) {
            console.log('authenticated from sessionId');
            this.onAuth(userInfo, null, true);
          } else {
            console.log('session not remembered in db: ', sid);
            this.logout(() => this.props.history.push(Login.url));
          }
        }, (e)=> {
          console.log(e);
        }).catch(e=>{
          console.log(e);
        }).finally(()=>changeConnectionState(true));
      } else changeConnectionState(true);
    }

    this.state = {
        logged: false,
        user: {},
        needsConsents: false,
        login: this.login,
        logout: this.logout,
        acceptRules: this.acceptRules,
        connected: false,
    };

    const changeConnectionState = (connected: boolean) => {
      this.setState({connected});
    }

    emitter.addListener('network', (data)=>{
      const connected = data.state;
      //przy sprawdzaniu ciasteczka opozniamy informacje o uzyskaniu polaczenia
      if (connected) this.checkCookie();
      else changeConnectionState(connected);
    });
  }

  render() {
    const connected = this.state.connected;
    if (!connected) return <Loading msg="Łączenie z serwerem" />
    return (
      <SessionContext.Provider value={this.state}>
        {this.props.children}
      </SessionContext.Provider>
    );
  }
}

export const SessionContextProvider = withRouter(_SessionContextProvider);
export const SessionContextConsumer = SessionContext.Consumer;
