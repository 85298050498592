import React from "react";
import type {UserWebInfoBasic, WebCredentials} from "../services/API";

/**
 * typ kontekstu oraz jego tworzenie musiał być wydzielony do osobnego pliku ponieważ sypał się błąd
 */
export type SessionContextType = {
    logged: boolean,
    user: UserWebInfoBasic,
    needsConsents: boolean,
    login: (credentials: WebCredentials) => void,
    logout: (cb: ()=>void)=>void,
    acceptRules: ()=>void,
    connected: boolean,
}

export const SessionContext = React.createContext();