/**
 * modale jeśli wyświetlają się nad jakimś komponenetem/ekranem muszą mieć url w formacie
 * /[url_komponentu]/modal/[...]
 * @type {string}
 */
import {RouteComponentProps, useHistory, useLocation} from "react-router-dom";


export const ModalUrl = '/modal/';

export type Location = {
    pathname: string;
}

const getLocation = (path: string): Location => {
    if (path.indexOf(ModalUrl) > -1) {
        return {pathname: path.substr(0, path.indexOf('/', 1)) };
    }
    return null;
}

export const useModalBackground = (props: RouteComponentProps): ?Location => {
    const location = useLocation();
    const path = location.pathname;
    const background = location.state ? location.state.background : getLocation(path);
    return background;
}

/**
 * zwraca funkcje zamykania okna modalnego, uwzgledniajac historie
 * jesli nie bylo wczesniejszych wpisow to wraca pod podany adres
 */
export const useHandleClose = (address: string, force: boolean = false): ()=>void => {
    const history = useHistory();
    return (history.length > 2 && !force) ? ()=>history.goBack() : ()=>history.push(address);
}
