/* @flow */
import {emitter} from './API'

//const serializeCookie = (name, val) => `${encodeURIComponent(name)}=${encodeURIComponent(val)}`;

/**
 * Klasa do obsługi połączenia sieciowego w oparciu o WebSocket oraz pesudo protokół komunikacji z serwerem.
 * W celu użycia należy przeciążyć/przypisać metodę onCall, gdy oczekiwane są wywołania z serwera.
 * Oraz wywołać metody send() dla wywołania metody po stronie serwera
 */
export class Network {

	_serverUrl: string;

	/**
	 * Obiekt do obsługi komunikacji
	 * @private
	 * @type WebSocket
	 */
	_ws : WebSocket;
	/**
	 * Czy jest połączenie z serwerem
	 */
	_connected : boolean = false;
	/**
	 * Mapa z obietnicami oczekującymi na odpowiedź z serwera.
	 */
	_promises : {};

	/**
	 * Generator identyfikatorów dla zapytań do serwera (na potrzeby odpowiedzi).
	 */
	_id : number = 0;


	constructor(server : ?string = null) {
		this._serverUrl=server;
		this._promises= {};
	}

	connect() : void {
		//if(this._ws!=null) throw new Error("Already connected!");
		console.log("Connecting to: "+this._serverUrl);
		this._ws=new WebSocket(this._serverUrl);
		// , [],
		// {
		// 	'headers': {
		// 		'Cookie': serializeCookie(SESSION_COOKIE, cookies.get(SESSION_COOKIE))
		// 	}
		// });
		this._ws.onopen = () => this._onConnected();
		this._ws.onclose = (e) => this._onClose(e);
		this._ws.onmessage= (m) => this._onMessage(m);
		this._ws.onerror= (e) => this._onError(e);
	}

	/**
	 * @private
	 */
	_onConnected() : void {
		this._connected=true;
		this.onConnected();
	}

	_onError(event : Event ) : void {
		this.onError(event);
	}

	/**
	 * Czy jest połączenie z serwerem
	 */
	isConnected() : boolean {
		return this._connected;
	}

	/**
	 * Metoda do wysyłania zapytania do serwera.
	 * @param {string} method nazwa metody serwerowej do wywołania
	 * @param {*} data dane do metody serwerowej w postaci w postaci obiektu lub typu prostego
	 * @return {Promise} zwraca obietnice
	 * @protected
	 */
	send(method : string, data : ?any ) : Promise<?any> {
		let id=++this._id;
		return new Promise((resolve, reject) => {
			if(!this._connected) {
				reject("Brak połączenia z serwerem");
			} else {
				// wysłanie danych do serwera w postaci nazwa "metody:id:dane".
				if(typeof(data)==='undefined') data=null;
				this._ws.send(method+":"+id+":"+JSON.stringify(data));
				// zapamiętujemy Id do czasu odpowiedzi
				this._promises[id]={ resolve, reject };
			}
		}).catch((e)=>{
			//polykamy wyjątki i ich nie rethrowujemy,
			//obsługa w 100% sprowadza się do pokazania notyfikacji
			console.log('error while invoking rpc method', e);
			throw e;
		});
	}

	/**
	 * @private
	 */
	_onMessage(message : MessageEvent) : void {
		if (this.onMessage(message)) return;
		let data =JSON.parse(message.data);
		console.debug("Got server message: ", message);
		if(typeof(data.id)==='number') {	// odpowiedź na wcześniejsze zapytanie
			let { reject, resolve } = this._promises[data.id];
			delete this._promises[data.id];	// zwalniamy
			if(typeof(data.error)!=='undefined') {
				reject(data.error);
			} else {
				resolve(data.data);
			}
		} else {	// wywołanie funkcji klienckiej przez serwer.
			let method=data.method;
			let arg=data.data;
			this.onCall(method, arg);
		}
	}

	/**
	 * @private
	 */
	_onClose(event : CloseEvent) : void {
		this._connected=false;
		for(let id in this._promises) {
			if(!this._promises.hasOwnProperty(id)) continue;
			let { reject } = this._promises[id];
			reject(event);
		}
		this._promises={};	// czyścimy
		this.onClose(event);
	}

	/**
	 * Metoda wywoływana po nawiązaniu połączenia. Do przeciążenia
	 * @public
	 */
	onConnected() : void {
		console.debug("Connected to server: ",this._serverUrl);
		this._connected = true;
		this.connectionChangeEvent();
	}

	/**
	 * Metoda wywoływana po utracie połączenia
	 * @public
	 */
	onClose(event : CloseEvent) : void {
		console.debug("Connection to server ended: ", event);
		this._connected = false;
		this.connectionChangeEvent();
	}

	connectionChangeEvent() {
		this._monitor();
		// let e = new CustomEvent('network',  { detail: this._connected});
		// window.dispatchEvent(e);
		console.log('connection change event: ', this._connected)
		emitter.emit('network', { state: this._connected });
	}

	/**
	 ** obłsuga reconnecta (rt = reconnect timer) oraz pinga (pt = ping timer)
	 ** pingowanie odbywa się bez nadawania id oraz promise
	 **/
	_monitor() {
		if (this.rt) clearInterval(this.rt);
		if (this.pt) clearInterval(this.pt);
		if (this._connected) {
			this.pt = setInterval(()=> {
				this._ws.send('__ping');
			}, 15000);
			return;
		}
		this.rt = setTimeout(()=>{
			console.log('trying to reconnect');
			this.connect();
		}, 5000);
	}

	/**
	 * Metoda wywoływana w przypadku błędu połączenia
	 * @public
	 */
	onError(event : Event) : void {
		console.warn("Connection error: ", event);
		this.connectionChangeEvent();
	}

	/**
	 * Metoda wywoływana w przypadku otrzymaniwa wiadomości z serwera
	 * @returns czy przerwać przetwarzanie wiadomości (obsługa ponga)
	 * @public
	 */
	onMessage(message : MessageEvent) : void {
		if (message.data && message.data === '__pong') {
			return true;
		}
		return false;
	}

	/**
	 * Metoda do obsługi wywołania metody z klienta. Należy ją przeciążyć!
	 * @protected
	 */
	onCall=function( method : string, data : ?any) : void {
		console.warn("onCall not overriden!");
	}
}

/**
 * Implementująca domyślne zachowanie dla komunikacji sieciowej.
 * serverAPI jest nadpisywany przez "proxy" i wywołania są przekazywane do serwera.
 * Dla clientImpl są tworzone wywołania w onCall.
 * @param {object} serverAPI instacja klasy z metodami, które występują po stronie serwera; metody nie powinny mieć implementacji i mogą mieć maksymalnie jeden argument
 * @param {object|null} clientImpl obiekt z metodami (tylko metodami; wynik jest ignorowany), które są możliwe do wywołania przez serwer.
 * @param {string|null} server adres websocketowy do serwera lub null, gdy ma być domyślny
 * @returns {Network}
 */
export function networkWrap(apis: any[], clientImpl : ?{} = null, server : ?string = null ) : Network {
	let net=new Network(server);

	if(typeof(clientImpl)==='object') {
		net.onCall=(method : string , data : ?any) => {
			let func=clientImpl[method];
			if(typeof(func)!=='function') {
				console.warn("Trying to call method '"+method+"' from server, but no such method exists on client side!");
				return;
			}
			let res=func.call(clientImpl, data);
			if(typeof(res)==='undefined' || res===null) {
				// OK
			} else {
				console.warn("Ignoring result of method '"+method+"'");
			}
		}
	}
	apis.forEach(serverAPI=>{
		let funcs=Object.getOwnPropertyNames(Object.getPrototypeOf(serverAPI));
		console.info("API defined methods: ",funcs);
		for(let i=0;i<funcs.length;++i) {
			let funcName=funcs[i];
			if(funcName==='constructor') continue;	// pomijamy konstruktor
			let func=serverAPI[funcName];
			console.debug("Checking ", funcName, ', type: ', typeof(func));
			if(typeof(func)!=='function') continue;
			serverAPI[funcName]=function() {
				// console.debug("Calling server method: ", funcName);
				return net.send(funcName, arguments[0]);
			}
			// console.debug("Wrapped "+funcName);
		}
	});

	return net;

}
