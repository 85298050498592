import React, {useContext} from 'react';
import {SessionContext} from './SessionContext';
import {Formik} from "formik";
import {Button, Form} from "react-bootstrap";
import {FormButtonGroup, FormikControl, FormRowGroup} from "./Components";
import {useHistory} from "react-router-dom";
import PrintersTab from "./PrintersTab";

const Consents = () => {
  const history = useHistory();

  const context = useContext(SessionContext);

  const submit = () => {
    context.acceptRules(()=>history.push(PrintersTab.url));
  }

  return <>
    <Formik initialValues={{accepted: false}} onSubmit={submit}>
      {({handleSubmit, values }) => (
          <>
            <h2>Regulamin użytkownika</h2>
            <p>{consentText}</p>
            <Form horizontal="true" noValidate onSubmit={handleSubmit}>
              <FormRowGroup controlId="acceptedId" label="">
                <FormikControl type="checkbox" name="accepted" label="Wyrażam zgodę"/>
              </FormRowGroup>
              <FormButtonGroup>
                <Button disabled={!values.accepted} variant="primary" type="submit">Dalej</Button>
              </FormButtonGroup>
            </Form>
          </>
      )}
    </Formik>;
  </>
}

Consents.url = '/consents';

export default Consents;

const consentText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet magna purus. Pellentesque interdum ligula orci, malesuada hendrerit ante fringilla sed. Cras commodo ornare sapien id eleifend. Nunc eget sapien urna. Nullam at massa id mauris tincidunt egestas. In dictum scelerisque erat in pulvinar. Duis venenatis mauris quam, vel sagittis enim suscipit nec. Quisque sit amet purus at felis convallis interdum. Fusce tincidunt rhoncus purus ut mattis. Maecenas facilisis vestibulum enim, ut faucibus orci. Proin rutrum id augue in rhoncus. Ut rutrum dui nec leo varius luctus. Donec aliquam posuere faucibus. Praesent venenatis, magna sit amet gravida pharetra, urna eros efficitur mi, eu auctor massa odio sit amet dolor.

Vestibulum scelerisque ultricies tincidunt. Etiam a finibus neque. Quisque pharetra aliquam dapibus. Duis non iaculis lacus. Nam placerat massa lorem, eu pulvinar lorem hendrerit consectetur. Maecenas ultricies vel enim pellentesque sollicitudin. Etiam nec sodales metus. Vestibulum nec felis luctus, tempor purus non, fringilla urna. Quisque aliquam ante ac posuere accumsan. Phasellus consectetur nisi eget condimentum efficitur. Suspendisse eget commodo augue, non congue justo. Nulla in cursus ipsum. In convallis euismod felis, id viverra lectus. Mauris eu ligula nulla.

Praesent sit amet leo in sapien maximus pretium non vel enim. Ut eget vestibulum est, et semper lectus. Ut ornare malesuada mollis. Cras et ex et justo hendrerit dictum. Etiam vel commodo lacus. Nam in arcu leo. Suspendisse eu aliquet nibh. Cras dictum nisi nec ex scelerisque, a faucibus nunc congue.

Cras egestas ex eget dui pellentesque consequat quis ut felis. Fusce a iaculis lectus. Proin consectetur a magna vel bibendum. Mauris pretium faucibus urna ac molestie. Integer feugiat, nulla non lobortis pellentesque, augue nulla commodo leo, quis iaculis ipsum libero non massa. Sed ut ornare nisi, vel semper felis. Praesent viverra nec felis pellentesque semper. Suspendisse lorem erat, scelerisque vitae libero vel, mattis euismod lorem. Phasellus maximus volutpat tellus, ut scelerisque lorem. Aliquam viverra vehicula molestie. Aliquam vel accumsan ipsum. Morbi eleifend ex pulvinar ante hendrerit, vel cursus risus dapibus. Morbi feugiat varius ullamcorper. Quisque sed volutpat nulla. Nam mollis justo urna, et congue felis sollicitudin vel. Quisque ut lacus ac mauris aliquet suscipit.

Integer a porttitor turpis, sit amet blandit magna. Etiam condimentum leo nec nulla dictum, eu porta nisi aliquet. Phasellus commodo lobortis gravida. Ut vitae nibh convallis, posuere lacus id, fermentum erat. Fusce eget tellus et dolor fermentum venenatis fringilla aliquam sapien. Praesent in posuere nibh. Cras bibendum venenatis dui quis scelerisque. Cras leo dui, convallis et urna eget, vestibulum luctus arcu. Sed quis tortor ut nunc suscipit aliquet. Morbi viverra ullamcorper magna vel congue. Vestibulum mi mi, mattis porttitor tortor dapibus, interdum iaculis ante. Suspendisse pretium, odio sit amet interdum tempor, est ante consectetur augue, nec malesuada ante justo eget tortor. Nam faucibus rhoncus neque eget tincidunt. Cras convallis tristique est. Sed nec laoreet velit.`;
