import {Button, Nav, Navbar, NavLink} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import documaster_logo from "../styles/images/documaster.png";
import {SessionContext} from "./SessionContext";
import {useHistory, useLocation} from "react-router-dom";
import PrintersTab from "./PrintersTab";
import Settings from "./Settings";
import LocationsTab from "./LocationsTab";
import {GlobalLoadingIndicator} from "./Components";
import Reports from "./Reports";

const NavRouterLink =({ href, children, ...props}: NavLinkProps) => {

    const history = useHistory();
    const location = useLocation();

    const _active = location.pathname === href;
    const [active, setActive] = useState(_active);

    useEffect(()=>{
        setActive(_active);
        // console.log('navbar: ', location.pathname)
        //react router redirect nie powoduje zmian w historii
        return history.listen((location, action)=>{
                // console.log(action, location.pathname, location.state)
            setActive(location.pathname === href)
        })
    }, []);

    return <NavLink
        active={active} href={href}
        onClick={(e) => {
            history.push(href);
            e.preventDefault();
        }}
        {...props}
    >{children}</NavLink>
}

const Navigation = () => {

    const context = useContext(SessionContext);
    const history = useHistory();

    return <Navbar expand="md" fixed="top" bg="light" collapseOnSelect>
            <Navbar.Brand>
                <img className="logo" src={documaster_logo} alt="Documaster"
                     onClick={(e)=> { history.push(PrintersTab.url); }}/>
                <span>{context.user.structure}</span>
            </Navbar.Brand>
        { (context.logged && !context.needsConsents) ? <>
            <Navbar.Toggle/>
            <Navbar.Collapse>
                <Nav className="mr-auto">
                    <NavRouterLink href={PrintersTab.url}>Drukarki</NavRouterLink>
                    <NavRouterLink href={LocationsTab.url}>Lokacje</NavRouterLink>
                    <NavRouterLink href={Reports.url}>Raporty</NavRouterLink>
                    <NavRouterLink href={Settings.url}>Ustawienia</NavRouterLink>
                </Nav>
                <GlobalLoadingIndicator />

                <Button className="logout" variant="outline-danger" title="Wyloguj"
                        onClick={(e)=>context.logout()}>Wyloguj {context.user.label}
                </Button>
            </Navbar.Collapse>
            </>
            : null }
    </Navbar>
}

export default Navigation;