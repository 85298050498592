import React, {useContext} from 'react';
import {SessionContext, SessionContextType} from "./components/SessionContext";
import Navigation from "./components/Navigation"
import Routes from "./Routes";
import Notifications from "./components/Notifications";

const App = () => {

    const context: SessionContextType = useContext(SessionContext);

    return <>
        <header>
            <Navigation />
        </header>
        <div className="content">
            <Routes />
        </div>
        <footer>
            {!context.connected && <p className="no-connection pull-left">Brak połączenia z serwerem!</p>}
            <p className="firm pull-right">{new Date().getFullYear()} Euroimpex SA</p>
        </footer>
        <Notifications />
    </>;
}

App.productName = 'GlobalService';

export default App;