import {Button, Form, Modal} from "react-bootstrap";
import React, {useMemo} from "react";
import {Formik} from "formik";
import {download, FormikControl, FormRowGroup, Loading} from "../Components";
import API, {DataAPI, MutationAPI, useData, useMutator} from "../../services/API";
import {useHandleClose, ModalUrl} from "../../commons/ModalHelpers";
import Reports from "../Reports";
import {useLocation} from "react-router-dom";
import type {ReportInfo} from "../../services/API";

const GenerateReport = () => {

    let location = useLocation();

    let {report} = location.state;
    let r: ReportInfo = report;
    const handleClose = useHandleClose(Reports.url);

    const initial = useMemo(() => {
        let initial = {};
        for (let param of r.params) {
            switch (param.type) {
                case 'date': {
                    initial[param.id] = new Date();
                    break;
                }
            }
        }
        return initial;
    }, [report]);

    let params = useMemo(() => {
        let params = [];
        let idx = 0;
        for (let param of r.params) {
            console.log('param', param);
            switch (param.type) {
                case 'date': {
                    params.push(
                        <FormRowGroup key={idx++} controlId={param.name} label={param.name}>
                            <FormikControl name={param.id} type="date"/>
                        </FormRowGroup>
                    );
                    break;
                }
                default: params.push(<p>Nieobsługiwany typ parametru: {param.type}</p>)
            }
        }
        return params;
    }, [report]);

    const convert = (value) => {
        if (value instanceof Date) {
            return value.getTime();
        }
        return value;
    }

    const submit = async (values, {setSubmitting}) => {
        let params = Object.keys(values).map(v=>{
            return {
                id: v,
                value: convert(values[v])
            }
        })
        try {
            setSubmitting(true);
            DataAPI.getReport({
                reportId: r.id,
                params
            }).then((response) => {
                console.log('response:', response);
                download('test.xlsx', response.data);
            })
        } finally {
            setSubmitting(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        console.log('values:', values)
        return errors;
    }

    console.log('initial: ', initial)

    return <Modal size="md" show={true} onHide={handleClose} animation={false}>
        <Formik initialValues={initial} onSubmit={submit} validate={validate}>
            {({ handleSubmit, isSubmitting, values}) => (
                <Form noValidate onSubmit={handleSubmit} className="form form-add-printer">
                    <Modal.Header closeButton>
                        <Modal.Title>{report.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {params}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={handleSubmit} disabled={isSubmitting}>
                            {isSubmitting ? <Loading msg="Trwa generowanie"/> : <span>Generuj</span>}</Button>
                        <Button variant="outline-primary" onClick={handleClose}>Anuluj</Button>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>
}

GenerateReport.url = '/reports/generate';
export default GenerateReport;
