import {RouteComponentProps} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {DataAPI} from "../services/API";
import {ButtonLink, Link, Loading} from "./Components";
import type {ReportInfo} from "../services/API";
import GenerateReport from "./modals/GenerateReport";
import AddEditLocation from "./modals/AddEditLocation";
import ReactQueryTable from "./ReactQueryTable";
import {Helmet} from "react-helmet";
import {OrdinalColumn} from "./ReactTable";
import AddEditDistribution from "./modals/AddEditDistribution";

const Reports = (props: RouteComponentProps) => {
    return <>
        <Helmet>
            <title>Raporty</title>
        </Helmet>
        <h1>Raporty</h1>
        <ReportList />
        <ReportDistribution />
    </>
}

const ReportList = () => {
    let [reports, setReports] = useState([]);

    useEffect(() => {
        DataAPI.getReports().then((response) => {
            setReports(response.reports);
        });
    }, []);

    let noReports = !reports || reports.length === 0;

    if (noReports) {
        return <Loading msg="Pobieranie informacji o raportach"/>
    }
    return (
        <div>
            <h2>Generowanie</h2>
            <ul>
            {
                reports.map((report: ReportInfo) => {
                    return (
                        <li key={report.id}>
                            <Link to={GenerateReport.url} state={{report}}>{report.name}</Link>
                        </li>
                    )
                })
            }
            </ul>
        </div>
    )
}

const ReportDistribution = () => {

    const columns = useMemo(()=>{
        return [
            OrdinalColumn,
            { Header: 'Nazwa', accessor: 'name' },
            { Header: 'Rozkład', accessor: 'cron' },
            { Header: 'Raport', accessor: 'reportName'}
        ]
    }, []);

    return (
        <div>
            <h2>Dystrybucja</h2>
            <ButtonLink variant="success" className="action float-right" to={AddEditDistribution.buildUrl('new')} >Dodaj lokacje</ButtonLink>
            <ReactQueryTable type="location" columns={columns} queryFunction={DataAPI.getReportDistributionList}/>
        </div>
    )
}


Reports.url = '/reports';

export default Reports;