import {Button, Form, Modal} from "react-bootstrap";
import {RouteComponentProps, useParams} from "react-router-dom";
import React from "react";
import {Formik} from "formik";
import {FormikControl, FormRowGroup} from "../Components";
import API, {DataAPI, MutationAPI, useData, useMutator} from "../../services/API";
import {Validation} from "../../commons/Utils";
import type { ReportDistributionInfo} from "../../services/API";
import {useHandleClose, ModalUrl} from "../../commons/ModalHelpers";
import {UseMutationResult} from "react-query";
import Notifications from "../Notifications";
import Reports from "../Reports";

const AddEditDistribution = (props: RouteComponentProps) => {

    const { id } = useParams();
    const isNew = id === 'new';

    const handleClose = useHandleClose(Reports.url);
    const mutateDistribution: UseMutationResult<ReportDistributionInfo> = useMutator(MutationAPI.addEditDistribution, {
        afterSuccess: handleClose,
        customMsg: 'Dystrybucja została ' + (isNew ? 'dodana' : 'zaktualizowana')
    });
    const { isLoading, error, data, invalidate } = useData(id, 'distribution', DataAPI.getDistribution, {enabled: !isNew});

    const submit = (values: ReportDistributionInfo) => {
        values.id = isNew ? null : values.id;
        mutateDistribution.mutate(values, {onSuccess: invalidate});
    }

    const validate = (values: ReportDistributionInfo) => {
        let err = {};
        // let l = values.name.length;
        // Validation.add(err, 'name', l < 3 ? 'Nazwa lokacji za krótka' : null);
        // Validation.add(err, 'name', l > 63 ? 'Nazwa lokacji za długa' : null);
        return err;
    }

    if (isLoading) return null;
    if (error) {
        Notifications.pushNew(error.message, 'danger');
        return null;
    }

    const dist: ReportDistributionInfo = data;

    const initial = isNew ? {name: '', cron: ''} : {name: dist.name };

    return <Modal size="md" show={true} onHide={handleClose} animation={false}>
        <Formik initialValues={initial} onSubmit={submit} validate={validate}>
            {({ handleSubmit, isSubmitting, values}) => (
                <Form noValidate onSubmit={handleSubmit} className="form form-add-printer">
                    <Modal.Header closeButton>
                        <Modal.Title>{isNew ? "Utwórz dystrybucje" : (dist.name + ' - Edycja')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormRowGroup label="Nazwa" controlId="nameId">
                            <FormikControl autoFocus={true} type="text" name="name" />
                        </FormRowGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={handleSubmit}>{isNew ? "Utwórz" : "Aktualizuj"}</Button>
                        <Button variant="outline-primary" onClick={handleClose}>Anuluj</Button>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>
}

export default AddEditDistribution;

AddEditDistribution.url = `/distribution${ModalUrl}edit/:id`
AddEditDistribution.buildUrl = location => AddEditDistribution.url.replace(':id', location ? location : 'new')