export const PrinterStateData = {
  ok: { label: 'Ok', font: 'ok', className:'state-ok' },
  serviceRequested: { label: 'Wymagany serwis', font: 'call-service', className:'state-error'},
  lowToner: { label: 'Niski poziom tonera', font: 'toner', className:'state-warn'},
  noToner: { label: 'Brak tonera', font: 'no-toner', className:'state-warn'},
  lowPaper: { label: 'Mało papieru', font: 'paper', className:'state-warn'},
  noPaper: { label: 'Brak papieru', font: 'paper', className:'state-warn'},
  jammed: { label: 'Zacięcie papieru', font: 'paper-jam', className:'state-warn'},
  offline: { label: 'Brak połączenia', tooltip: 'Maszyna nie przyjmuje prac', font: 'disconnected', className:'state-warn'},
  doorOpen: { label: 'Otwarta klapa', font: 'door-open', className:'state-warn'},
  inactive: { label: 'Urządzenie nie odpowiada', className:'state-warn' },
  notOk: { label: 'Wystąpiły alerty', font: 'warn',  className:'state-error'},
  manualCounterEntry: { label: 'Ręczne wprowadzenie licznika', font: 'ok', className:'state-ok'}
};

Object.freeze(PrinterStateData);