import React, {useContext} from 'react';
import { Form, Button} from 'react-bootstrap'
import {Redirect, useHistory} from "react-router-dom";
import {FormButtonGroup, FormikControl, FormRowGroup} from "./Components";
import {Formik} from "formik";
import {SessionContext} from "./SessionContext";
import type {WebCredentials} from "../services/API";
import Consents from "./Consents";
import PrintersTab from "./PrintersTab";
import type {SessionContextType} from "./SessionContext";

const labelSize = 2;


const Login = () => {

  const context: SessionContextType = useContext(SessionContext);
  const history = useHistory();

  //https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/Redirect.js
  if (context.needsConsents) return <Redirect to={Consents.url} push={true} />;
  else if (context.logged) return <Redirect to={PrintersTab.url} push={true}/>;

  const validate = (values) => {
    let errors = {};
    if (!values.user) errors.user = 'Nazwa użytkownika jest wymagana';
    else if (values.user.indexOf('@') === -1) {
      errors.user = 'Domena jest wymagana. Pełna postać loginu to [użytkownik]@[domena]';
    }
    if (!values.password) errors.password = 'Hasło jest wymagane'
    return errors;
  }

  const submit = (credentials: WebCredentials, {setSubmitting}) => {
    setSubmitting(true);
    context.login(credentials, (response: AuthResponse)=>{
      setSubmitting(false);
      if (context.needsConsents) history.push(Consents.url);
      else history.push(PrintersTab.url);
    });
  }

  return <Formik initialValues={{user: '', password: '', rememberMe: true}} onSubmit={submit} validate={validate}>
    {({handleSubmit, isSubmitting }) => (
        <Form horizontal="true" noValidate onSubmit={handleSubmit}>
          <FormRowGroup labelSize={labelSize} label="Użytkownik" controlId="userId">
            <FormikControl type="text" autoFocus name="user"/>
          </FormRowGroup>
          <FormRowGroup labelSize={labelSize} label="Hasło" controlId="password">
            <FormikControl type="password" name="password"/>
          </FormRowGroup>
          <FormRowGroup labelSize={labelSize} controlId="rememberMeId" label="">
            <FormikControl type="checkbox" name="rememberMe" label="Zapamiętaj mnie"/>
          </FormRowGroup>
          <FormButtonGroup labelSize={labelSize}>
            <Button variant="primary" type="submit" disabled={isSubmitting}>Zaloguj</Button>
          </FormButtonGroup>
        </Form>
    )}
  </Formik>;
}

Login.url = '/login'

export default Login;