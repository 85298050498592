import React, {useEffect, useState} from 'react';
import {Col, Container, FormGroup, Pagination, Row, Table} from "react-bootstrap";
import {SortDown, SortUp} from "./Font";
import {useSortBy, useTable, useAsyncDebounce, usePagination} from "react-table";
import {Form } from 'react-bootstrap';
import {Loading, SearchInput} from "./Components";
import {keyPressNavigate} from "../commons/Utils";
import {useHistory, useLocation} from "react-router-dom";

export type DataProvider = (pageIndex: number, pageSize: number, query?: string) => void;

type ReactTableProps = {
    columns: [],
    data?: Array<any>,
    emptyMsg?: string,
    onClick?: ()=>void,
    rowProps?: {},
    beforeRow?: any,
    afterRow?: any,
    globalSearch?: boolean,
    dataProvider?: DataProvider,
    isLoading?: boolean,
    pageCount?: number,
    totalCount?: number,
    startPageSize?: number,
    showIndex?: boolean,
    search?: boolean,
    serverSort: ()=>void,
    classes?: string
}

export const OrdinalColumn = { Header: 'Lp.', id: 'ordinal' , filterable: false,
    Cell: (data) => {
        let ps = data.state.pageSize;
        let start = ps ? data.state.pageIndex * ps : 0;
        return start + data.row.index + 1;
    }
};

const PaginationOptions = [5, 10, 50, 100];

const DATA_IDX = 'data-idx';

const ReactTable = (tableProps: ReactTableProps) => {

    const plugins = [];
    const data = tableProps.data || [];

    const location = useLocation();
    const history = useHistory();

    const DefaultState = {
        query: '',
        pageSize: tableProps.startPageSize || ReactTable.DefaultPageSize,
        pageIndex: 0,
        sort: null
    };

    const locationState = location.state || DefaultState;

    const [query, setQuery] = useState(locationState.query);
    const [srt, setSrt] = useState(locationState.sort);
    let options = {columns: tableProps.columns, data};

    /** żeby sprawdzić czy kolumna będzie sortowana programowo **/
    let colMap = {};
    let filterColumns = {};
    tableProps.columns.forEach(c=>{
        colMap[c.accessor] = c;
        if (typeof c.filterColumn === 'function') {
            filterColumns[c.Header] = c.filterColumn;
        }
        //sortujemy po stronie serwera!!!
        // if (!c.disableSortBy) {
        //     console.log('c sorttype: ', c.sortType);
        //     const c = (rowA, rowB, columnId: string, desc: boolean) => {
        //
        //     }
            // "impel10".localeCompare("impel11", undefined,  {
            //     numeric: true,
            //     sensitivity: 'base'
            // });
        // }
    });

    // const serverSort = isUndef(tableProps.serverSort) ? false : tableProps.serverSort;
    // const search = isUndef(tableProps.search) ? true : tableProps.search;
    // const filter = isUndef(tableProps.filter) ? false : tableProps.filter;
    const _pageCount = tableProps.pageCount ? tableProps.pageCount : Math.ceil(tableProps.totalCount/(locationState.pageSize || DefaultState.pageSize));

    plugins.push(useSortBy);
    if (tableProps.dataProvider) {
        plugins.push(usePagination);
        options.initialState = {
            pageIndex: locationState.pageIndex || DefaultState.pageIndex,
            pageSize: locationState.pageSize || DefaultState.pageSize
        };
        options.manualPagination = true;
        options.autoResetPage = false;
        options.autoResetSortBy = false;
        options.pageCount = _pageCount;
        if (tableProps.serverSort) options.manualSortBy = true;
    }

    const instance = useTable(options, ...plugins);
    // eslint-disable-next-line
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter, page, pageOptions, pageCount,
        gotoPage, setPageSize,
        state: {pageIndex, pageSize = tableProps.startPageSize || ReactTable.DefaultPageSize }, //, sortBy
    } = instance;

    useEffect(() => {
        if (tableProps.dataProvider) {
            // let sort = tableProps.serverSort ? sortBy[0] : null;
            // // console.log('tableProps.serverSort: ', tableProps.serverSort)
            // console.log('sortBy:' , sort)
            // console.log('state sort:' , srt)
            // if (sort) {
            //     let h = colMap[sort.id];
            //     sort.baseSort = typeof h.baseSort === 'undefined' ? true : h.baseSort;
            // }
            tableProps.dataProvider({pageIndex, pageSize, query, sort: srt});
        }
    }, [pageIndex, pageSize])

    const _rowProps = (data) => tableProps.rowProps ? tableProps.rowProps(data) : {};
    const _beforeRow = (data) => tableProps.beforeRow ? tableProps.beforeRow(data) : null;
    const _afterRow = (data) => tableProps.afterRow ? tableProps.afterRow(data) : null;

    const onSelected = (row, event) => {
        try {
            const idx = event.target.getAttribute(DATA_IDX);
            const cell = row.cells[idx];
            const val = cell.value;
            if (val) {
                const f = filterColumns[cell.column.Header];
                //znaleziony funckcje filtrująca
                if (f) {
                    //kliknieto w komórkę filtrująca, ignorujemy listener kliknięcia na wiersz
                    //jeśli jest
                    onSearch(f(val));
                    event.preventDefault();
                    return;
                }
            }
        } catch (e) {
            console.log('error while checking filterable column' + e);
        }
        if (tableProps.onClick) tableProps.onClick(row.original);
    }

    /**
     * zapisujemy w stanie histori obecne ustawienia tabelki
     * @param key
     * @param value
     */
    const changeTableHistory = (key: 'query' | 'pageIndex' | 'pageSize' | 'sort', value: number | string) => {
        let _state = location.state || DefaultState;
        _state[key] = value;
        console.log('changing to state: ', _state)
        history.replace({
            pathname: location.pathname,
            // search: '?query=' + value,
            state: _state
        });
    }

    const onSearch = (value?: string) => {
        if (query === value) return;
        changeTableHistory('query', value);
        setQuery(value);
        setCurrentPage(0);
        // setCurrentPageSize(pageSize);
        tableProps.dataProvider({pageIndex: 0, pageSize: pageSize, query: value});
    }

    const onSort = (column) => {
        setTimeout(()=>{
            let _srt = null;
            if (column.isSorted) {
                let h = colMap[column.id];
                _srt = {id: column.id, desc: column.isSortedDesc, baseSort: typeof h.baseSort === 'undefined' ? true : h.baseSort};
                setSrt(_srt);
            }
            setSrt(_srt);
            changeTableHistory('sort', _srt);
            tableProps.dataProvider({pageIndex, pageSize, query, sort: _srt});
        }, 100);
    }

    const setCurrentPage = (p: number) => {
        changeTableHistory('pageIndex', p);
        gotoPage(p);
    }

    const setCurrentPageSize = (size: number) => {
        changeTableHistory('pageSize', size);
        setPageSize(size);
    }

    let tbody;
    if (tableProps.isLoading) {
        tbody = <tr><td colSpan={tableProps.columns.length}><Loading/></td></tr>
    } else if (!data || !data.length) {
        tbody = <tr><td colSpan={tableProps.columns.length}>{tableProps.emptyMsg || 'Brak danych'}</td></tr>;
    } else {
        tbody = rows.map(
            (row, i) => {
                prepareRow(row);
                return <React.Fragment key={row.id}>
                    {_beforeRow(row.original)}
                    <tr {...row.getRowProps(_rowProps(row.original))} tabIndex={tableProps.onClick ? 0 : -1}
                        onClick={(event)=>onSelected(row, event)} onKeyPress={(e)=>
                            keyPressNavigate(e, (event, data) => event.target.tagName === 'TR', row.original, onSelected)}>
                        {
                            row.cells.map((cell, cellIdx) => {
                                const cn = `${cell.column.className ? cell.column.className : cell.column.id}${filterColumns[cell.column.Header] ? ' filterable' : ''}`
                                return <td {...cell.getCellProps({className: cn})} data-idx={cellIdx} title={cell.value} >
                                    {cell.render('Cell')}
                                </td>
                            })
                        }
                    </tr>
                    {_afterRow(row.original)}
                </React.Fragment>
            }
        );
    }

    let paginator = tableProps.dataProvider ? <Container fluid={true}><Row>
            <Col md={6}>
                {pageCount > 1 ?
                    <Paginator count={pageCount} currentPage={pageIndex} setPage={(p) => setCurrentPage(p)} />
                    : null
                }
            </Col>
            <Col md={6}>
                <Form horizontal="true">
                    <FormGroup as={Row} controlId="formControlsSelect">
                        <Form.Label column sm={6}>Wyników na stronę</Form.Label>
                        <Col sm={6}>
                            <Form.Control as="select" custom value={pageSize}
                                          onChange={e => {
                                              const size = Number(e.target.value);
                                              setCurrentPageSize(size);
                                          }}>
                                {
                                    PaginationOptions.map((pageSize) => <option key={pageSize}
                                                                                value={pageSize}>{pageSize}</option>)
                                }
                            </Form.Control>
                        </Col>
                    </FormGroup>
                </Form>
            </Col>
        </Row></Container>
        : null;

    return <>
        {/*<pre>*/}
        {/*    <code>*/}
        {/*      {JSON.stringify({pageIndex, pageSize, pageCount}, null, 2)}*/}
        {/*    </code>*/}
        {/*</pre>*/}

        {tableProps.search ?
            <Search change={onSearch} initial={query} /> : null }

        {tableProps.globalSearch ?
            <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter}/> : null }

        <Table className={tableProps.classes} responsive striped bordered hover size="sm" {...getTableProps()}>
            <thead>
            {
                headerGroups.map((hg) => (
                    <tr {...hg.getHeaderGroupProps()}>
                        {
                            hg.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`${column.className ? column.className : ''} ${column.id}`}
                                    onClick={(e)=>{
                                        console.log("before: ", column.isSorted)
                                        column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
                                        onSort(column);
                                    }}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {
                                            srt && srt.id === column.id ?  srt.desc ? <SortDown /> : <SortUp /> : ''
                                        }
                                    </span>
                                </th>
                            ))
                        }
                    </tr>
                ))
            }
            </thead>
            <tbody {...getTableBodyProps()}>
            {tbody}
            </tbody>
        </Table>
        {paginator}
    </>;
}

const Search = ({change, initial}: {change: (?string)=>void, initial?: string}) => {

    const onChange = useAsyncDebounce(value => {
        change(value)
    }, 1000);

    return <SearchInput className="filter" onSearch={(value, instant)=> instant ? change(value) : onChange(value)} initial={initial} minLength={2}/>
}

const GlobalFilter = ({ globalFilter, setGlobalFilter}) => {
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 1000);

    return <SearchInput className="filter" onSearch={onChange} minLength={2}/>

}

const Paginator = ({count, currentPage, setPage}: {count: number, currentPage: number, setPage: (page: number)=>void}) => {
    const [pages, setPages] = useState([]);

    useEffect(()=>{
        if (count > Paginator.shortMode) {
            let displayPerSide = Math.floor(Paginator.shortMode / 2);
            let low = currentPage - displayPerSide;

            let high = 0;
            if (low < 0) {
                high = currentPage + displayPerSide + Math.abs(low) + 1;
                low = 0;
            } else {
                high = currentPage + displayPerSide + 1;
                if (high > count) {
                    low = currentPage - (high - count) - displayPerSide;
                    high = count;
                }
            }
            setPages(Array.from({length: (high - low)}, (_, i) => low + i));
        }
    }, [currentPage]);

    if (count > Paginator.shortMode) {
        let displayPerSide = Math.floor(Paginator.shortMode / 2);

        return <Pagination>
            <Pagination.First disabled={currentPage === 0} onClick={()=>setPage(0)}/>
            <Pagination.Prev disabled={currentPage === 0} onClick={()=>setPage(currentPage-1)}/>
            {currentPage > displayPerSide ? <Pagination.Ellipsis disabled={true} /> : null}
            {
                pages.map((_page) => {
                    let page = _page + 1;
                    return <Pagination.Item className="page" onClick={()=>setPage(_page)} key={page}
                                            active={page === currentPage + 1}>{page}</Pagination.Item>
                })
            }
            {count - currentPage - 1 > displayPerSide ? <Pagination.Ellipsis disabled={true} /> : null}
            <Pagination.Next disabled={currentPage + 1 === count} onClick={()=>setPage(currentPage+1)} />
            <Pagination.Last disabled={currentPage + 1 === count} onClick={()=>setPage(count-1)}/>
        </Pagination>
    }
    return <Pagination>
        {
            [...Array(count).keys()].map((_page) => {
            let page = _page + 1;
            return <Pagination.Item className="page" onClick={()=>setPage(_page)} key={page}
            active={page === currentPage + 1}>{page}</Pagination.Item>
            })
        }
    </Pagination>
}
Paginator.shortMode = 11;

ReactTable.DefaultPageSize = 10;
// export default React.memo(ReactTable);
export default ReactTable;