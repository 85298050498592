import {Button, Form, Modal} from "react-bootstrap";
import {RouteComponentProps, useParams} from "react-router-dom";
import React from "react";
import {Formik} from "formik";
import {FormikControl, FormRowGroup} from "../Components";
import API, {DataAPI, MutationAPI, useData, useMutator} from "../../services/API";
import {Validation} from "../../commons/Utils";
import type {LocationWebInfo} from "../../services/API";
import {useHandleClose, ModalUrl} from "../../commons/ModalHelpers";
import LocationsTab from "../LocationsTab";
import {UseMutationResult} from "react-query";
import Notifications from "../Notifications";

const AddEditLocation = (props: RouteComponentProps) => {

    const { id } = useParams();
    const isNew = id === 'new';

    const handleClose = useHandleClose(LocationsTab.url);
    const addLocation: UseMutationResult<LocationWebInfo> = useMutator(MutationAPI.addEditLocation, {
        afterSuccess: handleClose,
        customMsg: 'Lokacja została ' + (isNew ? 'dodana' : 'zaktualizowana')
    });
    const { isLoading, error, data, invalidate } = useData(id, 'location', DataAPI.getLocation, {enabled: !isNew});

    const submit = (values: LocationWebInfo) => {
        if (!values.parent.id) values.parent = null;
        if (!isNew) values.id = location.id;
        addLocation.mutate(values, {onSuccess: invalidate});
    }

    const validate = (values: LocationWebInfo) => {
        let err = {};
        let l = values.name.length;
        Validation.add(err, 'name', l < 3 ? 'Nazwa lokacji za krótka' : null);
        Validation.add(err, 'name', l > 63 ? 'Nazwa lokacji za długa' : null);
        return err;
    }

    if (isLoading) return null;
    if (error) {
        Notifications.pushNew(error.message, 'danger');
        return null;
    }

    const location: LocationWebInfo = data;

    const initial = isNew ? {name: '', parent: ''} : {name: location.name };
    if (!isNew && location.parent) initial.parent =  {label: location.parent.path, id: location.parent.id};

    return <Modal size="md" show={true} onHide={handleClose} animation={false}>
        <Formik initialValues={initial} onSubmit={submit} validate={validate}>
            {({ handleSubmit, isSubmitting, values}) => (
                <Form noValidate onSubmit={handleSubmit} className="form form-add-printer">
                    <Modal.Header closeButton>
                        <Modal.Title>{isNew ? "Utwórz lokacje" : (location.name + ' - Edycja')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormRowGroup label="Nazwa" controlId="nameId">
                            <FormikControl autoFocus={true} type="text" name="name" />
                        </FormRowGroup>
                        <FormRowGroup label="Lokacja nadrzędna" controlId="parentId">
                            <FormikControl type="oracle" id="parentOracleId" name="parent"
                                   onSearch={(text)=>API.getLocations(text).then((res)=>res.data)}
                            />
                        </FormRowGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={handleSubmit}>{isNew ? "Utwórz" : "Aktualizuj"}</Button>
                        <Button variant="outline-primary" onClick={handleClose}>Anuluj</Button>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>
}

export default AddEditLocation;

AddEditLocation.url = `/locations${ModalUrl}edit/:id`
AddEditLocation.buildUrl = location => AddEditLocation.url.replace(':id', location ? location : 'new')