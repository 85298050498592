import React, {useEffect} from 'react';

import {Button, Form, FormGroup, Row} from "react-bootstrap";
import {PrinterStateData} from "../commons/Const";
import {DataAPI, MutationAPI, NotificationSettings, useData, useMutator} from "../services/API";
import {Formik} from "formik";
import {FormButtonGroup, FormikControl, Loading} from "./Components";
import {PrinterState} from "./PrinterStates";


const PrinterNotifications = ({printerId}) => {

    useEffect(()=>{
        console.log('mount for: ', printerId);
        return ()=>console.log('unmount for: ', printerId);
    }, []);

    const changeNotifications = useMutator(MutationAPI.saveNotifications, {
        customMsg: 'Zapisano ustawienia notyfikacji.'
    });

    const { isLoading, error, data, invalidate } = useData(printerId, 'printer-notifications', DataAPI.getNotifications, { refetchOnMount: true});

    const submit = (values, {setSubmitting}) => {
        setSubmitting(true);
        const req: NotificationSettings = {printerId, notificationKeys: values.alerts};
        changeNotifications.mutate(req, {
            onSettled: ()=>setSubmitting(false),
            onSuccess: invalidate
        });
    }

    if (isLoading) return <Loading />;
    if (error) return <p>{error.message}</p>;

    const keys: string[] = data.notificationKeys;

    return <div className="notifications">
        <h2>Ustawienia notyfikacji</h2>
        <Formik enableReinitialize={true} initialValues={{alerts: keys}} onSubmit={submit} >
            {({ handleSubmit, values, isSubmitting, handleReset }) => (
                <Form noValidate onSubmit={handleSubmit} className="form form-change-notify">
                    <FormGroup as={Row}>
                        <FormikControl key={keys} type="checkboxGroup" name="alerts" builder={(key)=><PrinterState entry={PrinterStateData[key]} />} domain={AlertsDomain}/>
                    </FormGroup>
                    <FormButtonGroup>
                        <Button variant="primary" disabled={isSubmitting} type="submit">Zapisz</Button>
                    </FormButtonGroup>
                </Form>
            )}
        </Formik>
    </div>;
}
const AlertsDomain = Object.keys(PrinterStateData).filter(k=>k!=='ok'&&k!=='notOk'&&k!=='manualCounterEntry');
export default PrinterNotifications;