import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Button, Col, FormGroup, Form, Row, InputGroup, FormControl, CloseButton} from "react-bootstrap";
import {Field,  useFormikContext} from "formik";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {SpinnerIcon, ZoomIn} from "./Font";
import { useIsFetching } from 'react-query'
import {format, parse} from "date-fns";
// import sanitize from "sanitize-filename";
//TODO typy
export const ButtonLink = ({to, children, ...props}) => {
    let history=useHistory();
    let location=useLocation();
    let { state, ...rest } = props;
    return <Button onClick={(e)=>{
        history.push({
            pathname: to,
            state: { background: location, ...state }
        })
    }} {...rest}>
        {children}
    </Button>
}

export const Link = ({to, children, ...props}) => {
    let history=useHistory();
    let location=useLocation();
    let { state, ...rest } = props;
    return <a href="#" onClick={(e)=>{
        e.preventDefault();
        history.push({
            pathname: to,
            state: { background: location, ...state }
        })
    }} {...rest}>
        {children}
    </a>
}

export const Loading = (props: {msg?: string}) => <div className="loader"><SpinnerIcon /><span>{props.msg || 'Ładowanie...'}</span></div>

export const SearchInput = ({onSearch, placeholder, minLength = 3, initial, className, ...props}) => {
    const [text, setText] = useState(initial || '');

    const search = (e) => {
        let val = '';
        if (e === null) onSearch(val, true)
        else {
            val = e.target.value;
            if (val.length >= minLength) onSearch(val);
            else if (val !== text) {
                //wpisano za malo znakow do przeszukiwania, czyscimy wyniki
                onSearch('');
            }
        }
        setText(val);
    }

    // const search = (e) => {
    //     let val = e ? e.target.value : '';
    //     if (val.length >= minLength || e === null) onSearch(val);
    //     else if (val !== text) onSearch('');
    //     setText(val);
    // }

    return <InputGroup className={`search-input ${className}`} {...props} title="Szukaj">
        <InputGroup.Prepend>
            <InputGroup.Text><ZoomIn /></InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl type="text" value={text} placeholder={placeholder || 'Wpisz minimum ' + minLength + ' znaki'}
                     onChange={search} autoFocus />
        {text && <span className="clear" onClick={()=>search(null)}>
            <span className="cancel-search">&times;</span>
        </span> }
    </InputGroup>
}

export const FormikControl = ({name, type, ...props}) => {
    const { setFieldValue, initialValues } = useFormikContext();

    const fieldChanged = (field, form, val) => {
        // console.log('setting field: ', field, ', value: ', val)
        setFieldValue(field.name, val);
        //setfield value nie ustawia touched
        form.setFieldTouched(field.name, true)
    }

    for (const prop in initialValues) {
        if (initialValues[prop] == null) {
            initialValues[prop] = '';
        }
    }

    return (
        <Field name={name}>
            {
                ({ field, form, meta }) => {
                    let component;
                    switch (type) {
                        default: throw new Error('not handled component: ' + type);
                        // case 'date':
                        //     component = <Form.Control as={DatePicker} {...field} {...props}
                        //                               dateFormat={props.showTimeSelect ? dateTimeFormat : dateFormat} selected={field.value}
                        //                               onChange={val => fieldChanged(field, form, val)} />;
                        //     break;
                        case 'radio' :
                            component = <Form.Check type="radio" {...field} {...props} />;
                            break;
                        // case 'radioGroup':
                        //     component = <Form.Control as={RadioGroup} onRadioChange={val=>{
                        //         fieldChanged(field, form, val)
                        //     }} {...field} {...props} />;
                        //     break;
                        case 'checkbox':
                            component = <Form.Check custom={true} type="checkbox" checked={meta.value} {...field} {...props}></Form.Check>;
                            break;
                        case 'checkboxGroup':
                            component = <CheckboxGroup values={meta.value} changed={(value)=>fieldChanged(field, form, value)} {...props} />
                            break;
                        case 'textarea':
                            component = <Form.Control as={'textarea'} {...field} {...props} isInvalid={meta.touched && meta.error}/>
                            break;
                        case 'text':
                        case 'number':
                        case 'password':
                            component = <Form.Control type={type} {...field} {...props} isInvalid={meta.touched && meta.error}/>
                            break;
                        case 'display':
                            const empty = props.emptyMsg ? props.emptyMsg : '[brak]';
                            //to coś nie do końca działa
                            // console.log('initialValues[field.name]: ', initialValues[field.name])
                            if (!initialValues[field.name]) {
                                initialValues[field.name] = empty;
                            }
                            component = <Form.Control readOnly={true} plaintext={true} type={type} {...field} {...props}/>
                            break;
                        // case 'tmce':
                        //     component = <Form.Control as={Editor} initialValue={meta.value} init={tmceInit(props)}
                        //                               onEditorChange={val=>fieldChanged(field, form, val)} />
                        //     break;
                        case 'oracle':
                            component = <Oracle {...field} {...props} onChange={val => {fieldChanged(field, form, val)}} />
                            break;
                        case 'map':
                            component = <Map initial={meta.value} onChange={val => {fieldChanged(field, form, val)}}/>
                            break;
                        case 'datetime':
                            component = <Form.Control
                                isInvalid={meta.touched && meta.error}
                                type='datetime-local'
                                {...field}
                                value={meta.value ? dateTimeLocalSet(meta.value) : ''}
                                onChange={(e)=>{
                                    let value: any = e.target.value;
                                    if (value) value = dateTimeLocalGet(value);
                                    form.setFieldValue(name, value, true);
                                }}
                            />;
                            break;
                        case 'date':
                            component = <Form.Control type="date" {...field} {...props} isInvalid={meta.touched && meta.error}
                                value={meta.value ? dateFormatSet(meta.value) : ''}
                                onChange={(e)=>{
                                    let value: any = e.target.value;
                                    console.log('value: ', value)
                                    if (value) value = dateFormatGet(value);
                                    fieldChanged(field, form, value);
                                }}
                            />
                            break;
                    }
                    return <>
                        {component}
                        { (meta.touched && meta.error) ?
                            // bootstrapowy feedback musi być bezpośrednio po inpucie, co nie działa dla datepickera
                            <div className="feedback-invalid" type="invalid">{meta.error}</div>
                            : null
                        }
                    </>
                }
            }
        </Field>
    )
};

const DATETIME_LOCALFORMAT = "yyyy-MM-dd'T'HH:mm"

export const download = (name: string, file: string) => {
    // name = sanitize(name);
    let element = document.createElement('a');
    try {
        element.setAttribute('href', 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + file);
        element.setAttribute('download', name);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
    } finally {
        document.body.removeChild(element);
    }
}

const dateTimeLocalGet = (date: string): Date => {
    return parse(date, DATETIME_LOCALFORMAT, new Date());
}

const dateTimeLocalSet = (date: Date): string => {
    return format(date, DATETIME_LOCALFORMAT);
}

const dateFormatGet = (date: string): Date => {
    return parse(date, 'yyyy-MM-dd', new Date());
}

const dateFormatSet = (date: Date): string => {
    return format(date, 'yyyy-MM-dd');
}

export type MapObj = {
    key: string,
    value: string
}

const Map = ({onChange, initial}: {onChange: (Object)=>void}) => {

    const [fields: MapObj[], setFields] = useState(initial || []);

    useEffect(() => {
        onChange(fields);
    }, [fields]);

    const changeAdditonal = (e, idx, prop) => {
        fields[idx][prop] = e.target.value;
        setFields([...fields]);
    }
      
    const add = () => {
        setFields(fields.concat({key: '', value: ''}));
    }
    
    const remove = (idx) => {
        fields.splice(idx, 1);
        setFields([...fields]);
    }

    return <>
        <Button variant="outline-success" onClick={add}>Dodaj</Button>
        <table className="map">
            <tbody>
            {
              fields.map((f,idx)=>{
                  return <>
                    <tr>
                        <td>Nazwa</td>
                        <td><Form.Control type="text" placeholder="Nazwa" value={fields[idx]['key']} onChange={(e)=>changeAdditonal(e, idx, 'key')}/></td>
                        <td>Wartość</td>
                        <td><Form.Control type="text" placeholder="Wartość" value={fields[idx]['value']} onChange={(e)=>changeAdditonal(e, idx,  'value')} /></td>
                        <td><Button variant="outline-danger" onClick={()=>remove(idx)}>Usuń</Button></td>
                    </tr>
                  </>
              })
            }
            </tbody>
        </table>
    </>
}

/**
 * zamiast useEffect można nadać key={meta.value}
 */
const CheckboxGroup = ({changed, values, props, domain, builder = (key)=>key}: { values: string[], builder?:(key)=>any}) => {
    const [value, setValue] = useState(values);

    useEffect(()=>{
        setValue(values);
    }, [values]);

    return <>
        {domain.map(v => <Form.Check key={v} type="checkbox" id={v + 'id'} custom={true} checked={value.indexOf(v) > -1}
                                     {...props} label={builder(v)}
                                     onChange={(e) => {
                                         //kopia state
                                         const _value = value.slice(0, value.length);
                                         if (e.target.checked) _value.push(v)
                                         else {
                                             const index = _value.indexOf(v);
                                             if (index > -1) _value.splice(index, 1);
                                         }
                                         setValue(_value);
                                         changed(_value);
                                     }}/>)
        }
        </>
}

const DefaultLabelSize = 2;

export const FormRowGroup=({ label, controlId, labelSize = DefaultLabelSize, children }: {
    label: string|React$Node,
    controlId: string,
}) => {
    return <FormGroup as={Row} controlId={controlId}>
        <Form.Label column md={labelSize}>{label || null}</Form.Label>
        <Col md={12 - labelSize}>{children}</Col>
    </FormGroup>
}

export const FormButtonGroup = ({labelSize = DefaultLabelSize, children}) => {
    return <Form.Row>
        <Col sm={{ offset: labelSize }}>{children}</Col>
    </Form.Row>
}

export type OracleProps = any & {
    clearOnSelect?: boolean,
    emptyLabel?: string,
    onChange: (Object)=>void,
    onSearch: (string)=>Promise
}

export const Oracle = (props: OracleProps) => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(props.value ? props.value.label : '');
    const [loading, setLoading] = useState(false);
    const typeahead = useRef(null);

    const search = (query)=>{
        setLoading(true);
        props.onSearch(query).then((options)=>{
            if (props.filter) options = props.filter(options);
            setOptions(options);
            setLoading(false);
        });
    }

    const change = (val) => {
        //tutaj dostajemy listę wybranych (ale zezwalamy tylko na 1)
        let selected = val && val.length > 0 ? val[0] : '';
        if (props.clearOnSelect) setTimeout(() => typeahead.current.clear(), 0);
        if (typeof props.convert === 'function') selected = props.convert(selected);
        props.onChange(selected);
        setValue(selected);
        if (!selected) typeahead.current.clear();
    }
    return <div className="oracle">
        <AsyncTypeahead {...props} defaultInputValue={value} emptyLabel="Brak wyników"
                            ref={typeahead} labelKey="label" multiple={props.multiple || false} isLoading={loading}
                        onChange={change} options={options} minLength={props.minLength || 2}  onSearch={search} />
        {value ?
            <CloseButton onClick={()=>change(null)} />
            : null }
        </div>;
}

/**
 * wskażnik ładowania/wykonywania zapytania, pokazujemy tylko,
 * gdy pojawia się jeśli zapytanie trwa dłużej niz GlobalLoadingIndicator.offScreenTime
 */
export const GlobalLoadingIndicator = (props) => {
    const isFetching = useIsFetching();
    const [showing, setShowing] = useState(false);

    useEffect(()=>{
        if (GlobalLoadingIndicator.timer) {
            clearInterval(GlobalLoadingIndicator.timer);
            GlobalLoadingIndicator.timer = 0;
        }
        if (isFetching) {
            GlobalLoadingIndicator.timer = setTimeout(()=>setShowing(true), GlobalLoadingIndicator.offScreenTime);
        } else setShowing(false);
    }, [isFetching]);
    return showing ? <div className="query-fetch">
        <Loading msg="Atkualizowanie danych" />
    </div> : null;
}

GlobalLoadingIndicator.timer = 0;
GlobalLoadingIndicator.offScreenTime = 500;