import React, {useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import Notifications from "../Notifications";
import {DataAPI, MutationAPI, useData, useMutator} from "../../services/API";
import {RouteComponentProps, useParams} from "react-router-dom";
import type {LocationWebInfo} from "../../services/API";
import {ModalUrl, useHandleClose} from "../../commons/ModalHelpers";
import LocationsTab from "../LocationsTab";

const DeleteLocation = (props: RouteComponentProps) => {

    const { id } = useParams();
    const [firstRun, setFirstRun] = useState(true);
    const [msg, setMsg] = useState(null);

    const { isLoading, error, data, invalidate } = useData(id, 'location', DataAPI.getLocation);

    const handleClose = useHandleClose(LocationsTab.url);
    const deleteLocation = useMutator(MutationAPI.deleteLocation, {
        notifyExecute: false,
        afterSuccess: (data)=>{
            if (data) {
                setMsg(data);
                setFirstRun(false)
            } else {
                //notyfikujemy dopiero przy 2 runie
                Notifications.pushNew('Akcja wykonana', 'info');
                handleClose();
            }
        }
    });

    const handleDelete = () => deleteLocation.mutate({ lwi: location, firstRun }, {onSuccess: invalidate});

    if (isLoading) return null;
    if (error) {
        Notifications.pushNew(error.message, 'danger');
        return null;
    }

    const location: LocationWebInfo = data;

    return (
        <Modal size="sm" show={true} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{location.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Czy napewno chcecsz usunąć lokację?</p>
                {msg ? <p className="text-danger">{msg}</p> : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>Usuń</Button>
                <Button variant="primary" onClick={handleClose}>Anuluj</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteLocation;

DeleteLocation.url = `/locations${ModalUrl}delete/:id`
DeleteLocation.buildUrl = location => DeleteLocation.url.replace(':id', location ? location : 'new')