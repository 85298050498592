import React, {useContext} from 'react';
import {Redirect, Route, RouteProps, Switch, useLocation} from "react-router-dom";
import Login from "./components/Login";
import Consents from "./components/Consents";
import PrintersTab from "./components/PrintersTab";
import Settings from "./components/Settings";
import LocationsTab from "./components/LocationsTab";
import AddEditLocation from "./components/modals/AddEditLocation";
import PrinterDetails from "./components/PrinterDetails";
import DeleteLocation from "./components/modals/DeleteLocation";
import DeletePrinter from "./components/modals/DeletePrinter";
import {SessionContext} from "./components/SessionContext";
import {useModalBackground} from "./commons/ModalHelpers";
import Reports from "./components/Reports";
import GenerateReport from "./components/modals/GenerateReport";
import AddEditDistribution from "./components/modals/AddEditDistribution";

const Routes = (props: RouteProps) => {

    const location = useLocation();
    const background = useModalBackground();

    return <>
        {background?
            <Switch>
                <PrivateRoute path={AddEditLocation.url} component={AddEditLocation}/>
                <PrivateRoute path={DeleteLocation.url} component={DeleteLocation}/>
                <PrivateRoute path={DeletePrinter.url} component={DeletePrinter}/>
                <PrivateRoute path={GenerateReport.url} component={GenerateReport}/>
                <PrivateRoute path={AddEditDistribution.url} component={AddEditDistribution}/>
            </Switch>
            : null
        }
        <Switch location={background || location}>
            <Route path="/" exact>
                <Redirect to={PrintersTab.url} from="/" push={true}/>
            </Route>
            <Route path={Login.url} component={Login} />
            <Route path={Consents.url} exact component={Consents}/>
            <PrivateRoute path={PrintersTab.url} exact component={PrintersTab}/>
            <PrivateRoute path={Settings.url} exact component={Settings}/>
            <PrivateRoute path={LocationsTab.url} exact component={LocationsTab}/>
            <PrivateRoute path={Reports.url} component={Reports}/>
            <PrivateRoute path={PrinterDetails.url} exact component={PrinterDetails}/>
            <Route path="*" component={ErrorScreen}/>
        </Switch>
    </>
}

export default Routes;

const PrivateRoute = (props) => {
    const context = useContext(SessionContext);
    if (context.logged) return <Route {...props} />
    return <Redirect to={{ pathname: Login.url, state: { from: props.location } }} />
}

const ErrorScreen = (props: RouteComponentProps) => {
    return <h1>Błąd nie znaleziono: "{props.location.pathname}"</h1>;
}