import React, {useEffect, useReducer} from 'react';
import { Table, Alert } from 'react-bootstrap';
import PrinterStates from './PrinterStates';
import API, {DbHandler} from '../services/API';
import type {PrinterHistoryResult} from "../services/API";
import { RouteComponentProps} from "react-router-dom";
import {Loading} from "./Components";

const reducer = (state, action) => {
    switch(action.type) {
        case 'loading':
            return { ...state, loading: true};
        case 'loaded-more':
            const data: PrinterHistoryResult & { newEntries: boolean } = action.payload;
            return {loading: false, first: data.first, last: data.last,
                history: data.newEntries ? [...data.history, ...state.history] : [...state.history, ...data.history] }
        case 'loaded-end':
            return { ...state, isMore: false, loading: false} ;
        default: throw new Error('not handled action: ' + action);
    }
}
const initialState = { history: [], last: null, first: null, loading: false, isMore: false }

type Props = RouteComponentProps & {
    printer: number;
}

const PrinterHistory = (props: Props) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(()=>{
        const onScroll = () => {
            const scrolledToBottom = (window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight);
            if (scrolledToBottom && state.isMore) getData();
        }

        window.addEventListener('scroll', onScroll, false);
        const handler: DbHandler = API.clientApi.registerHandler('printerevent', (change, id)=>{
            const first = this.state.first;
            if (first) this.getData(first, null);
            else console.log('got printerhistory change before first data was loaded, not refreshing');
        });

        getData(null);
        return () => {
            handler.unregister();
            window.removeEventListener('scroll', onScroll, false);
        }
    }, []);


    const getData = (fromParam, limitParam) => {
        if (state.loading) return;
        dispatch({type: 'loading'});
        const newEntries = typeof limitParam !== 'undefined';
        const from = newEntries ? fromParam : state.last;
        const limit = newEntries ? limitParam : 30;

        API.getPrinterHistory({ printerId: props.printer, from, limit }).then(result=>{
          if (result) dispatch({type: 'loaded-more', payload: result})
          else dispatch({type: 'loaded-end'})
        });
    }

    if (!state.history || !state.history.length) return <Alert variant="primary">Brak danych o historii drukarki</Alert>;

    return <div className="printer-history">
        <h2>Historia drukarki</h2>
        <Table responsive striped hover>
        <thead>
          <tr>
            <th>Data</th>
            <th>Stan</th>
          </tr>
        </thead>
        <tbody>
          {
            state.history.map((row, idx)=><tr key={idx}>
                <td>{row.date}</td>
                <td><PrinterStates key={row.idx} short={false} status={row.errorStates} /></td>
            </tr>)
          }
          {state.loading && <tr><td colSpan="2"><Loading /></td></tr>}
        </tbody>
        </Table>
    </div>
}

export default PrinterHistory;