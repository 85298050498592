import React, {useEffect, useState} from 'react';
import ReactTable from "./ReactTable";
import {useQueryClient} from "react-query";
import {Loading} from "./Components";
import type {DataProviderReq, TableQuery, TableResult} from "../services/API";
import API, {DbHandler, useData} from "../services/API";

export type ReactQueryTableProps = ReactTableProps & {
    type? :string;
    queryFunction: (p: any)=>Promise<any>
}

const ReactQueryTable = (props: ReactQueryTableProps) => {

    const [query, setQuery] = useState(null);

    const queryClient = useQueryClient();
    const { isLoading, error, data, key }  = useData(query, props.type, props.queryFunction,{ enabled: query != null });

    useEffect(()=>{
        if (props.type) {
            console.log('registering listener on type: ', props.type)
            const handler: DbHandler = API.clientApi.registerHandler(props.type, (change, id)=> {
                console.log('got change: ', change, ', id: ', id, ' - invalidating cache: ', key)
                queryClient.invalidateQueries(key)
            });
            return handler.unregister;
        }
    }, []);

    const dataProvider = async ({pageSize, pageIndex, query, sort}: DataProviderReq) => {
        const q: TableQuery = {count: pageSize, offset: pageIndex * pageSize, filter: query,
                            ascending: sort ? !sort.desc : false, sorting: sort ? sort.id : null};
        console.log('tableQuery: ', q, ', pageSize: ', pageSize)
        setQuery(q);
    }

    if (error) return <p className="text-danger">{error.manager}</p>;
    if (isLoading) return <Loading />;

    const tableResult: TableResult = data;
    const count = tableResult ? tableResult.totalCount : 0;
    const tableData = tableResult ? tableResult.data : [];

    return <ReactTable classes={props.type} columns={props.columns} isLoading={false} serverSort={true} totalCount={count}
                       dataProvider={dataProvider} search={true} data={tableData} {...props} />
}

export default ReactQueryTable;