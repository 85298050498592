import React from 'react';
import {Button, Form} from 'react-bootstrap';
import {Formik} from "formik";
import {FormButtonGroup, FormikControl, FormRowGroup, Loading} from "./Components";
import {DataAPI, MutationAPI, useData, useMutator, UserWebInfoBasic} from "../services/API";
import PrinterNotifications from "./PrinterNotifications";
import {Validation} from "../commons/Utils";
import {Helmet} from "react-helmet";
import {FormikBag} from "formik";

const Settings = () => {
  return <>
    <Helmet>
      <title>Ustawienia</title>
    </Helmet>
    <PasswordChange />
    <EmailChange />
    <PrinterNotifications />
    <hr />
    <a href="/apps/downloadRicoh" target="_blank"><Button variant="primary">Pobierz aplikację</Button></a>
  </>;
}

const PasswordChange = () => {

  const changePassword = useMutator(MutationAPI.changePassword, {
    customMsg: 'Hasło zostało zmienione'
  });

  const validate = (values) => {
    const errors = {};
    const op = values.oldPassword;
    const p = values.password;
    const pr = values.passwordRepeat;

    if (!op) errors.oldPassword =  'Poprzednie hasło jest wymagane';
    if (!p) errors.password = 'Nowe hasło jest wymagane';
    else if (p.length < 5) errors.password ='Hasło jest za krótkie (Wymagane minimum 5 znaków)';
    if (!pr) errors.newPassword = 'Powtórzone nowe hasło jest wymagane';
    if (p && pr && p !== pr)
      errors.password = errors.passwordRepeat = 'Hasła nie zgadzają się';
    return errors;
  }

  const submit = (values, {setSubmitting, resetForm}: FormikBag) => {
    const oldPassword = values.oldPassword;
    const newPassword = values.password;
    setSubmitting(true);
    changePassword.mutate({ oldPassword, newPassword }, { onSettled: ()=>{
      resetForm();
      setSubmitting(false)
    } });
  }

  return <div className="password-change">
    <h2>Zmiana hasła</h2>
    <Formik initialValues={{oldPassword: '', password: '', passwordRepeat: ''}} onSubmit={submit} validate={validate}>
      {({ handleSubmit, values, isSubmitting, handleReset }) => (
        <Form noValidate onSubmit={handleSubmit} className="form form-change-password">
          <FormRowGroup controlId="oldPasswordId" label="Obecne hasło">
            <FormikControl type="password" name="oldPassword" />
          </FormRowGroup>
          <FormRowGroup controlId="passwordId" label="Nowe hasło">
            <FormikControl type="password" name="password" />
          </FormRowGroup>
          <FormRowGroup controlId="passwordRepeatId" label="Powtórz nowe hasło">
            <FormikControl type="password" name="passwordRepeat" />
          </FormRowGroup>
          <FormButtonGroup>
            <Button variant="primary" disabled={isSubmitting} type="submit">Zmień</Button>
            <Button variant="primary" onClick={handleReset}>Anuluj</Button>
          </FormButtonGroup>
        </Form>
    )}
    </Formik>
  </div>;
}

const EmailRegexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const EmailChange = () => {

  const { isLoading, error, data, invalidate } = useData(null, 'user', DataAPI.getUserWebInfo);
  const changeEmail = useMutator(MutationAPI.saveEmail, { customMsg: 'E-mail zapisany'});

  const validate = (values) => {
    const err = {};
    if (values.email) Validation.add(err, 'email', Validation.regexp(values.email, EmailRegexp, 'Nieprawidłowy adres e-email'));
    return err;
  }

  const submit = (values, {setSubmitting}) => {
    setSubmitting(true);
    changeEmail.mutate(values.email, {
      onSuccess: (ui: UserWebInfoBasic)=> {
        setSubmitting(false);
        invalidate();
      }
    })
  }

  if (isLoading) return <Loading />;
  if (error) return <p>{error.message}</p>;

  const userInfo: UserWebInfoBasic = data;

  return <div className="email-change">
    <h2>Zmiana adresu e-mail</h2>
    <Formik initialValues={{email: userInfo.email}} onSubmit={submit} validate={validate}>
      {({ handleSubmit, values, isSubmitting, handleReset, isValid }) => (
          <Form noValidate onSubmit={handleSubmit} className="form form-change-email">
            <FormRowGroup controlId="emailId" label="Adres e-mail">
              <FormikControl type="text" name="email" />
            </FormRowGroup>
            <FormButtonGroup>
              <Button variant="primary" disabled={isSubmitting || !isValid} type="submit">Zapisz</Button>
              <Button variant="primary" onClick={handleReset}>Anuluj</Button>
            </FormButtonGroup>
          </Form>
      )}
    </Formik>
  </div>;
}

Settings.url = '/settings';

export default Settings;
