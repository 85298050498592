import React from 'react';
import DocumasterFont from "./DocumasterFont";

/**
 *-2 - nieznany stan tonera (?),
 * -100 - prawie pusty 1-10%,
 * -3 - jak widać to trochę, czyli 10-100%,
 **/
const TonerLevel = ({label, customStyle, value}) => {
  switch(value) {
    case -3:
      value = 'trochę'; break;
    case -2:
      value = 'nieznany'; break;
    case -100:
      value = 'prawie pusty'; break;
    case 0:
      value = 'pusty'; break;
    default: break;
  }
  return (
      <span title={label}>
      <DocumasterFont label={label} symbol="toner" style={customStyle}/>
        {value}
    </span>
  );
}

const TonersInfo = ({printer}) => {
  let t = [];
  if (printer.tonerCyan) {
    t =  t.concat([
      { label: "Cyjan", customStyle: "cyan", value: printer.tonerCyan },
      { label: "Magenta", customStyle: "magenta", value: printer.tonerMagenta },
      { label: "Żółty", customStyle: "yellow", value: printer.tonerYellow },
    ]);
  }
  if (printer.tonerBlack) {
    t.push({ label: "Czarny", customStyle: "black", value: printer.tonerBlack });
  }
  return <span>{t.map(e=><TonerLevel key={e.label} {...e}/>)}</span>;
}

export default TonersInfo;