import React from 'react';
import { PrinterStateData } from '../commons/Const';
import DocumasterFont from './DocumasterFont';

export type PrinterStateProps = {
    data?: any[],
    short: boolean,
    maxStates?: number
}

export const PrinterState = ({short = false, entry} : {short: boolean, key: number | string, entry: any}) => {
    const key = entry.label;
    const icon = <DocumasterFont label={entry.label} key={key} style={entry.className} symbol={entry.font} />;
    if (short) return icon;
    return <span className="state" key={key}>
                <span title={entry.tooltip || ''} key={key + 's'}>{entry.label}</span>
                {icon}
            </span>;
}

const PrinterStates = (props: PrinterStateProps) => {
    const data = props.status || [];
    const short = props.short;
    if (!data || !data.length) data.push('ok');
    if (props.maxStates && data.length > props.maxStates) {
        return <PrinterState key="notOk" short={short} entry={PrinterStateData['notOk']} />
    }
    return <div className={`status-info ${short ? 'short' : ''}`}>
        {
            data.map((key, idx)=> {
                const entry = PrinterStateData[key];
                if(entry === undefined) return null;
                return <PrinterState key={key} short={short} entry={entry} />
            })
        }
    </div>
}

export default PrinterStates;