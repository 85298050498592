import React, {useCallback, useEffect, useState} from 'react';
import Toast from "react-bootstrap/Toast";
import {format} from "date-fns";
import {Danger, Info} from "./Font";
import {emitter} from "../services/API";
import App from "../App";
import {RouteComponentProps} from "react-router-dom";

export type NotificationType = 'success' | 'danger' | 'warning' | 'info';

export type NotificationData = {
    msg : string, type: NotificationType
}

const Notifications = (props: RouteComponentProps) => {

    const [data, setData] = useState([]);

    const listener = useCallback((newNotification : NotificationData)=>{
        setData(prevData => [...prevData, newNotification]);
    }, []);

    useEffect(()=>{
        emitter.on('notification', listener);
        return ()=>{
            emitter.removeListener('notification', listener);
        }
    }, []);

    const removeNotification = (index: number) => setData(data.splice(index));

    return <div className="notifications-wrapper">
        {
            data.map((n, index)=> {
                if (!n.msg) { console.log('wrong notification received'); return null; }
                return <NotificationToast key={index} {...n} onClose={()=>{removeNotification(index)}}/>
            })
        }
    </div>
}

Notifications.pushNew = (msg: string, type: NotificationType = 'info')=>{
    emitter.emit(Notifications.Event, { msg, type, time: format(new Date(), 'HH:mm:ss') })
}
Notifications.Event = 'notification';

// let cnt = 0;
// setInterval(()=>{
//      if (cnt > 3) return;
//     console.log('pushing msg' + cnt)
//     Notifications.pushNew('jakas wiadomość: ' + ++cnt, Math.random() < 0.5 ? 'info': 'danger');
// }, 3000)

export default Notifications;


const NotificationToast = (props: NotificationData) => {

    const [show, setShow] = useState(true);

    return (
        <Toast className={`toast-${props.type}`} onClose={() => setShow(false)} show={show} delay={NotificationToast.DELAY_AUTOHIDE} autohide>
            {/*delay={5000} autohide*/}
            <Toast.Header>
                <strong className="mr-auto">
                    {props.type === 'danger' ? <Danger /> : <Info />}
                    <span>{App.productName}</span>
                </strong>
                <small>{props.time}</small>
            </Toast.Header>
            <Toast.Body dangerouslySetInnerHTML={{ __html: props.msg}}></Toast.Body>
        </Toast>
    );
}

NotificationToast.DELAY_AUTOHIDE = 5000;
