import React, {useMemo} from 'react';
import {OrdinalColumn} from "./ReactTable";
import {ButtonLink} from "./Components";
import AddEditLocation from "./modals/AddEditLocation";
import DeleteLocation from "./modals/DeleteLocation";
import {RouteComponentProps} from "react-router-dom";
import type {LocationWebInfo} from "../services/API";
import {DataAPI} from "../services/API";
import {Helmet} from "react-helmet";
import ReactQueryTable from "./ReactQueryTable";

const LocationsTab = (props: RouteComponentProps) => {

  const columns = useMemo(()=>[
      OrdinalColumn,
      { Header: 'Nazwa', accessor: 'name' },
      { Header: 'Ścieżka', accessor: 'path', disableSortBy: true},
      { Header: 'Akcje',
        id: 'actions',
        Cell: ({ row }) => {
          const lwi: LocationWebInfo = row.original;
          return <div className="actions">
            <ButtonLink size="sm" variant="outline-danger" to={AddEditLocation.buildUrl(lwi.id)} >Edytuj lokacje</ButtonLink>
            <ButtonLink size="sm" variant="outline-danger" to={DeleteLocation.buildUrl(lwi.id)} >Usuń lokacje</ButtonLink>
          </div>
        }, disableSortBy: true
      }
    ], []);

    return <>
      <Helmet>
        <title>Lokacje</title>
      </Helmet>
      <ButtonLink variant="success" className="action float-right" to={AddEditLocation.buildUrl('new')} >Dodaj lokacje</ButtonLink>
      <ReactQueryTable type="location" columns={columns} queryFunction={DataAPI.getLocationList}/>
    </>;
}

LocationsTab.url = '/locations';

export default LocationsTab;