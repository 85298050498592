//@flow
import { networkWrap } from './Network.js';
import {EventEmitter} from "eventemitter3";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UseQueryResult, UseMutationResult, UseMutationOptions} from "react-query";
import Notifications from "../components/Notifications";
import * as Utils from "../commons/Utils";

export type TableState<T> = {
  data?: T[],
  loading: boolean,
  pageCount: number,
  pageSize: number,
  pageIndex: number
}

export type WebCredentials = {
  user: string,
  password: string,
  rememberMe: boolean
}

export type UserWebInfoBasic = {
  sessionId: string,
  label: string,
  admin: boolean,
  email?: string,
  needsConsents: boolean,
  structure: string
}
export type AdditionalData = {
  key: string,
  value?: string
}

export type LocationWebInfo = {
  id: number,
  name: string,
  path: string,
  label: string,
  parent?: LocationWebInfo
}

export type PrinterEditable = {
  nis: string,
  clientId?: string,
  location?: string,
  lwi?: LocationWebInfo,
  description?: string,
  additionalData?: AdditionalData[]
}
export type PrinterWebInfo = PrinterEditable & {
  name: string,
  id: number,
  macNumber: string,
  serialNumber: string,
  totalCounter?: number,
  monoCounter?: number,
  colorCounter?: number,
  scanBWCounter?: number,
  scanColorCounter?: number,
  errorStatess?: string[],
  tonerCyan?: number,
  tonerMagenta?: number,
  toneryYellow?: nubmer,
  tonerBlack?: number,
  alerts?: string[],
  lastContacted?: string,
  lastContactedAgo?: number
}

export type TableResult<T> = {
  data?: T[],
  totalCount: number
}

export type PrinterResult = TableResult<PrinterWebInfo>;

export type LocationResult = TableResult<LocationWebInfo>;

export type LocationAddEditRequest = {
  id?: number,
  name: string,
  parent?: LocationWebInfo
}

export type TableSortRequest = {
  id: string,
  desc: boolean,
  baseSort: boolean
}

export type DataProviderReq = {
  pageSize: number,
  pageIndex: number,
  query: string,
  sort: TableSortRequest
}

export type TableQuery = {
  /** skip **/
  offset: number,
  /** limit **/
  count: number,
  /** szukane słowo **/
  filter: string,
  /** kolumna po której sortujemy **/
  sorting: string,
  ascending: boolean
}

export type PrinterHistoryEntry = {
  data: string,
  dateRaw: number,
  errorStates?: string[]
}

export type PrinterHistoryResult = {
  history?: PrinterHistoryEntry[],
  first: number,
  last: number
}

export type PasswordChangeRequest = {
  id?: number,
  oldPassword: string,
  newPassword: string
}

export type ViewRange = {
  type: string,
  removed: boolean,
  observer: string[]
}

export type NotificationSettings = {
  printerId: number,
  notificationKeys?: string[]
}

export type LocationDeleteRequest = {
  lwi: LocationWebInfo,
  firstRun: boolean
}

export type GSQueryResult<T> = UseQueryResult<T, any> & {
  key: string,
  invalidate: ()=>void
}

export type ReportParamType = 'date';

export type ReportParam = {
    id: string,
    name: string;
    type: ReportParamType;

}

export type ReportInfo = {
  id: string;
  name: string;
  params: ReportParam[];
}

export type ReportData = {
  reports: ReportInfo[];
}

export type ReportParamValue = {
  id: string,
  value: any;
}

export type ReportRequest = {
  id: string,
  params: ReportParamValue[]
}

export type ReportResponse = {
  name: string;
  //base64 enc report
  data: string;
}

export type ReportDistributionInfo =  {
  id: number | null;
  name: string;
  cron: string;
  emails: string;
  reportId: string;
}


export type ReportDistributionResult = TableResult<ReportDistributionInfo>;

class _DataAPI {
  getPrinterDetails(id: number): Promise<PrinterWebInfo> {}
  getPrinters(req: TableQuery): Promise<PrinterResult> {}
  getLocationList(req: TableQuery): Promise<LocationResult> {}

  getLocation(id: number): Promise<LocationWebInfo> {}
  getUserWebInfo(): Promise<UserWebInfoBasic> {}
  getNotifications(printerId: number): Promise<NotificationSettings> {}

  getReports(): Promise<ReportData> {}
  getReport(req: ReportRequest): Promise<ReportResponse> {}

  getReportDistributionList(req: TableQuery): Promise<ReportDistributionResult> {}
  deleteDistribution(id: number): Promise<void> {}

  getDistribution(id: number): Promise<ReportDistributionInfo> {}
}

class _MutationAPI {
  addEditLocation(location: LocationWebInfo): Promise<LocationWebInfo> {}
  deletePrinter(id: number): Promise<void> {}
  deleteLocation(req: LocationDeleteRequest): Promise<string> {}

  updatePrinter(pwi: PrinterWebInfo): Promise<void> {}
  saveEmail(email: string): Promise<UserWebInfoBasic> {}
  saveNotifications(settings: NotificationSettings): Promise<void> {}
  changePassword(password: PasswordChangeRequest): Promise<void> {}

  addEditDistribution(req: ReportDistributionInfo): Promise<void> {}
}

export type MutationParams = {
  before?: ()=>{},
  afterSuccess?: (data: any)=>{},
  mutationOpts?: {},
  customMsg?: string,
  notifyExecute: boolean
}

export const MutationAPI: _MutationAPI = new _MutationAPI();
/**
 * obiekt wykonujący zapytania przez webscockety
 * @type {_DataAPI}
 */
export const DataAPI: _DataAPI = new _DataAPI();

export const useMutator = <P, R, P, TError, TVariables, TContext>(method: (data: P)=>Promise<R> , params: MutationParams): UseMutationResult<P, TError, TVariables, TContext> => {
  const options: UseMutationOptions<P> = {
    onError: (error, variables, context) => {
      console.log('error: ', error)
      Notifications.pushNew(error, 'danger');
    },
    onSuccess: (data, variables, context) => {
      if (Utils.isUndef(params.notifyExecute) || params.notifyExecute) {
        Notifications.pushNew(params.customMsg ? params.customMsg : 'Akcja wykonana', 'info');
      }
      if (Utils.isFun(params.afterSuccess)) params.afterSuccess(data);
    },

    onMutate: (vars) => {
    }
  };
  return useMutation((d: P)=>method(d), options);
}

export type Keys = 'printer' | 'location';

export const useData = <P, R>(param: P, key: Keys, method: (param: P)=>Promise<R>, options): GSQueryResult<R> => {
    const queryClient = useQueryClient();
    const paramKey = !options || Utils.isUndef(options.useParamInKey) ? true : options.useParamInKey;
    const queryKey = paramKey ? [key, param] : key;
    // console.log('getting data for: ' + JSON.stringify(queryKey))
    const queryObj = useQuery(queryKey, ()=> method(param), options);
    queryObj.key = queryKey;
    //wszystkie po kluczu
    queryObj.invalidate = ()=>{
      // console.log('invalidating: ' + JSON.stringify(key))
      queryClient.invalidateQueries(key);
    }
    return queryObj;
}

/**
 * zapytania, które nie są ani mutatorami ani cacheowanymi query
 */
class _API {
  authenticate(credentials: WebCredentials): Promise<UserWebInfoBasic> {}
  signout(): Promise<void>  {}
  acceptRules(): Promise<void>  {}

  getPrinterHistory(id: number, from: number, count: number): Promise<PrinterHistoryResult>  {}
  getLocations(query: string): Promise<LocationResult> {}
}

export type Handler = {
  unregister: ()=>void
}

export class DbHandler {
  unregister: ()=>void;
  constructor(handler, unregister) {
    this.handler = handler;
    this.unregister = unregister;
  }
}

class _ClientAPI {
  constructor() {
    this.handlers = {};
  }

  /**
   tą metodę wywołuje tylko klient, powinna być gdzieś indziej
   **/
  registerHandler(type, handler) {
    let h = this.handlers[type];
    if (!h) h = this.handlers[type] = [];
    let dbh = new DbHandler(handler, ()=> {
      let idx =  h.indexOf(dbh);
      if (idx > -1) h.splice(idx, 1);
    });
    h.push(dbh);
    return dbh;
  }

  dbChange(change) {
    let id = change.id;
    let objType = change.objType;
    let changeType = change.changeType;
    let toInvoke = this.handlers[objType];
    //console.log('got: ', change, ' - found: ', toInvoke ? toInvoke.length : '[none]', ' handlers for type:', objType);
    if (toInvoke) toInvoke.forEach((h, idx)=>{
      h.handler(changeType, id);
    });
  }
}


const API = new _API();
const ClientAPI = new _ClientAPI();

const server=process.env.REACT_APP_SERVER;
let url;
if((typeof(server)==='string') && server.length>0) {    // tryb developerski
  url="ws://"+process.env.REACT_APP_SERVER.substr(7)+"/ws/data"; // zakładamy że zawsze jest http://
} else {
  url=((window.location.protocol==='https:')?'wss':'ws')
      +"://"
      +window.location.hostname+
      (window.location.port>0?":"+window.location.port:"")
      +"/ws/data";
}

let ns = networkWrap([API, DataAPI, MutationAPI], ClientAPI, url);
ns.connect();

API.clientApi = ClientAPI;

export default API;

export const emitter = new EventEmitter();
