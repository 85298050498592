import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom'
import { SessionContextProvider } from './components/SessionContextProvider';
import App from "./App";
import {Helmet} from "react-helmet";
import {QueryClient, QueryClientProvider} from "react-query";
import './styles/index.scss';

import { ReactQueryDevtools } from 'react-query/devtools'
const queryClient = new QueryClient();

const RQDebug = false;

ReactDOM.render(
    <Router>
        <Helmet titleTemplate={"%s - " + App.productName} defaultTitle={App.productName} />
        <QueryClientProvider client={queryClient}>
            <SessionContextProvider>
                <App />
            </SessionContextProvider>
            { RQDebug ? <ReactQueryDevtools initialIsOpen={false} /> : null }
        </QueryClientProvider>
    </Router>,
    document.getElementById('root')
);